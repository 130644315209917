import { Component, OnInit, ViewChild,ElementRef } from '@angular/core';
import { ApiService } from '../../../../service/api.service';
import { CoreService } from '../../../../service/core.service';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import {Router} from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { environment } from '../../../../../environments/environment';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';

@Component({
  selector: 'app-purchase-quotation',
  templateUrl: './purchase-quotation.component.html',
  styleUrls: ['./purchase-quotation.component.css']
})


export class PurchaseQuotationComponent implements OnInit {
  @ViewChild('inputItemId', { static: false }) inputItemId: ElementRef;
  @ViewChild('selectproductId', { static: false }) selectproductId: NgSelectComponent;
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '350px',
    minHeight: '350px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: environment.php_api_server + '/api/saless/upimgs',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };
  terms = '<div style="text-align: center;"><span style="background-color: transparent;"><b><u>Terms &amp; Conditions</u></b></span></div><div style="text-align: center;"><span style="background-color: transparent;"><b><u><br></u></b></span></div><div style="text-align: left;"><span style="background-color: transparent;">1.</span></div>';
  purchsub_gd_id: number;
  godown_all: any;
  purchaseno: any;
  currency_all:any;
  purchasedate: Date;
  reference:any;
  confirmation_date: Date;
  delivery_date: Date;
  purchase_types: { id: number; name: string; }[];
  acc_types = [
    { id: 1, name: 'New' },
    { id: 2, name: 'Registered' }
  ];
  supp_acc_type: any;
  supp_new_name: any;
  errObjArr = {
    dtErr: null,
    purch_round_sign: null,
    purch_roundoff: null
  };
  purch_type_taxcat_id: number;
  prd_tax_cat_id: number;
  purch_tax_ledger_id: number;
  taxListCategories: any;
  taxCategories: any;
  taxpercentage: any;
  purchType: any;
  p_type_id: number;
  taxProdCategories: any;
  ledger_all: any;
  supplierAll: any
  supp_address: any;
  stkprd_all: any[];
  productSearchLoader: boolean;
  selecTed: any;
  searchResult: string;
  baseUnitRate: any;
  currentStock :any;
  prd_base_unit_name: any;
  prd_base_unit_name_master: any;
  stkunitsel: any;
  purchsub_unit_id: any;
  unit_qty: any;
  boxname: any;
  private modalRef: NgbModalRef;
  closeResult: string;
  purchsub_qty: any;
  baserate: any;
  taxamount: any;
  totalqty: number;
  purchprice: number;
  taxamounttotal: number;
  purchaserateperbox: number;
  purchpricetotal: number;
  boxqty: number;
  taxvalperqty: number;
  taxvaltotqty: number;
  boxdisplaybaseqty: number;
  printError: any[];
  items = [];
  resDup: any;
  slno: any;
  boxdiplayname: any;
  puchaseListTable: boolean;
  unit_qty_inp: number;
  totpurchprice: number;
  purch_tax: number;
  purch_cgst: number;
  purch_sgst: number;
  purch_igst: number;
  sel_gd_id: number;
  stkprdsel: string;
  purch_pay_type: any;
  purchtypesel: any;
  settings: any;
  round_sign = 1;
  roundoff: any;
  roundoff_sign = [
    { value: 1, name: '+', selecetd: true },
    { value: -1, name: '-' }

  ];
  stkprdbc_all: string[];
  discount: any;
  item_desc: any;
  is_description: any;
  purchpageLoadingImg: boolean;
  resultobj: any = {};
  previewData: any;
  is_prew_description: any;
  purch_amount: number;
  date: Date;
  serializedDate: any;
  branch_qt_no: any;
  supp_id: any;
  branch_name: any;
  branch_address: any;
  img_url: any;
  branch_phone: any;
  branch_mob: any;
  exchange_rate = 1;
  branch_tin: any;
  branch_reg_no: any;
  base_url: string;
  listing: boolean;
  list_purchase: any;
  curpage: any;
  lastpage: any;
  from: any;
  pgstart: boolean;
  pgend: boolean;
  previewLoader: boolean;
  is_listing: boolean;
  datevar: any;
  comments: any;
  update: boolean;
  lowStockItems: any;
  savedTemplate = {
    
    logo: false,
    header_type: 1,
    header_height: 35,
    footer_height: 32,
    page_no_display: 0,
    left_h: 92.6,
    botom_h: 26.4,
    page_format: ''
  }
  logoImgUrl: any;
  tempImgUrl: any;
  up: any;
  srch_mtd: number;
  multipleCurrency = false;
  item_barcode: string;
  sales_settings: any;
  po_print_style: any;

  stats_name={
    '0':'Purchased',
    '1':'pending'
  }
  isEdit: boolean;
  updateMrp = false;
  baseUnit: [];
  baseUnitName: any;
  country_dec: string;
  cmp_tax: any;
  cgst_perc: any;
  sgst_perc: any;
  branch_state: any;
  supp_details: any;
  cust_state_id: any;
  igst: boolean=false;
  footImgUrl: string;
  prod_alias: any;
  cur_lang: string;
  showBal = false;
  accLoading = false;
  sup_bal_sign: '';
  sup_tin: any;
  sup_balance: 0;
  supp_default_currency_id = 0;
  currencies = [];
  selcurrency: any;
  multibaserate: number;
  multitaxamount: number;
  multibaseratetax: number;
  usr_block_pre_date_select: any;
  minDate:Date;
  water_mark: string;
  constructor(private apiService: ApiService, private coreService: CoreService, private modalService: NgbModal, private datePipe: DatePipe,private route: Router,private translate: TranslateService,) { }
  

  ngOnInit() {
    // this.usr_block_pre_date_select = this.coreService.getUserData("usr_block_pre_date_select");
    // if(this.usr_block_pre_date_select){
  // }
    

    this.cur_lang = sessionStorage.getItem("baseLang");
    this.aliasChecked()
    this.apiService.cur_lang.subscribe(value =>{
          this.cur_lang = value;
          this.aliasChecked()
        })
       
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    this.purchsub_gd_id = Number(this.coreService.getUserData('usr_default_godown_id'));
    this.puchaseListTable = false;

    this.roundoff = 0.00;
    this.listPurchase(1);
    this.getTemplete();
    this.apiService.getClientSettingBykey({ key: 'multiple_currency_support' }).subscribe((res) => {
      if (res['data']) {
        this.multipleCurrency = (res['data']['cs_value']) ? true : false;
      }
    });

    this.apiService.listAllCurrency().subscribe((res) => {
      this.currencies = res.data;
    });

    this.getGodownList();
    this.getNextPurchaseQtn();
    this.supp_acc_type = 2;
    this.searchSupplier('');

    this.apiService.getAllTaxCategory().subscribe((resp) => {
      this.taxCategories = resp.data;
      this.taxListCategories = resp.data;
      this.taxProdCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);


    });
    this.allCurrency();
    this.getPurchSetting();
    this.discount = 0;
    this.is_description = [];
    this.item_desc = '';
    this.is_prew_description = [];

    this.branch_name = this.coreService.getUserData('branch_name');
    this.branch_address = this.coreService.getUserData('branch_address');
    this.img_url = this.coreService.getUserData('img_url');
    this.branch_phone = this.coreService.getUserData('branch_phone');
    this.branch_mob = this.coreService.getUserData('branch_mob');
    this.branch_tin = this.coreService.getUserData('branch_tin');
    this.branch_reg_no = this.coreService.getUserData('branch_reg_no');
    this.base_url = location.origin;
    this.getLowStockProds();
    this.up = this.coreService.getUserPrivilage();
    this.po_print_style=1;
    this.getSalesSetting()
    this.country_dec=this.coreService.setDecimalLength();
    if(this.cmp_tax==2){
      this.branch_state = this.coreService.getUserData('branch_state_id');
     
    }
  }
  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
    
    }else{
      this.prod_alias =null;
      
    }
  }
  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.sales_settings = res['data'];
        this.po_print_style = this.sales_settings.po_print_style;
      } else { 
        this.po_print_style=1;
      }
    });
    

  }

  //form search  ledger
  searchLedger(search: string) {
    let searchval = new FormData();
    searchval.append("ledger_name", search);
    this.apiService.getLedByName(searchval).subscribe((res) => {
      this.ledger_all = res['data'];

    });
  }



  getSupBalance(sup_id, $exchngeRate = null) {
    console.log("this.cust_state_id");
    console.log(this.cust_state_id);
    console.log("this.branch_state");
     console.log(this.branch_state);
     console.log("this.supp_id");
     console.log(this.supp_id);
     

    this.showBal = false;
    this.accLoading = false;
    if (sup_id) {
      this.supplierdetails(sup_id);
      this.accLoading = true;
      this.apiService.getSupBalance({ sup_id: sup_id }).subscribe((res) => {
        this.accLoading = false;
        this.sup_balance = res.data.bal_without_sign;
        this.sup_bal_sign = res.data.bal_sign;
        this.sup_tin = res.data.supp_tin;
        this.supp_default_currency_id = res.data.default_currency_id;
        if (this.supp_default_currency_id) {
          this.selcurrency = this.currencies.find(x => x.cur_id == this.supp_default_currency_id);
          if ($exchngeRate == null) {
            this.exchange_rate = this.selcurrency.cur_exchange_rate;
          } else {
            this.exchange_rate = $exchngeRate;
          }
        } else {
          this.selcurrency = null;
          this.exchange_rate = 1;
        }
        this.showBal = true;
      });
    }

  }
  // form search supplier
  searchSupplier(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }
  searchSupplierAlias(search: string) {
    const searchval = new FormData();
    searchval.append('supp_name', search);
    searchval.append('with-alias','1'); 
    this.apiService.searchSupplier(searchval).subscribe((res) => {
      this.supplierAll = res.data;
    });
  }

  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];
      // this.godown_all.push({ "gd_id": 0, 'gd_name': 'Shop' });

      this.godown_all.reverse();

    });
  }

  //form search product
  searchStkPrd(search: any, eventChar) {
    let searchval = new FormData();
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    searchval.append("prod_name", search);
    this.apiService.quicPurchkSearch(searchval).subscribe((res) => {
      // this.apiService.getProdByName(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }
  

  allCurrency(){
   
    this.apiService.listAllCurrency().subscribe((res) => {
      this.currency_all = res.data;
      this.currency_all.push({ "cur_id": 0, 'cur_name': 'Default' });

   
     
    });

  }

  purchaseBox(stkunitsel: any) {
    this.selecTed.purchase_rate = this.baseUnitRate * stkunitsel.unit_base_qty;
    this.baserate = Number(this.selecTed.purchase_rate);
    if(this.multipleCurrency && this.supp_default_currency_id != 0 && this.selcurrency){
      this.multibaserate=  Number(this.selecTed.purchase_rate / this.exchange_rate);
    // this.multibaseratetax = Number( (this.baserate * this.taxpercentage)/100);
    // this.multitaxamount=Number(this.multibaseratetax/ this.exchange_rate);
    }
    this.selecTed.current_stock = this.currentStock / stkunitsel.unit_base_qty;
    this.unit_qty = stkunitsel.unit_base_qty;
    this.purchsub_unit_id = stkunitsel.unit_id;
    this.basicRatesBox();
    this.boxdiplayname = stkunitsel.unit_code;
    if (this.unit_qty != 1) {
      this.boxname = stkunitsel.unit_code;
    }
    else {
      this.boxname = "nil";
    }

  }

  basicRatesBox() {
    //  calculate single rate

    //diff
    this.unit_qty_inp = this.unit_qty;

    // this.singl/erate = this.baserate / (this.purchsub_qty * this.unit_qty_inp);

    //   change tax amount
    this.taxamount = (this.baserate * this.taxpercentage) / 100;
    this.totalqty = this.purchsub_qty * this.unit_qty_inp;
    this.purchprice = this.baserate;
    this.taxamounttotal = this.totalqty * this.taxamount;
    this.purchaserateperbox = this.purchprice / this.unit_qty_inp;
    this.purchpricetotal = this.purchaserateperbox * this.totalqty;
    this.boxqty = this.totalqty / this.unit_qty_inp;
    this.taxvalperqty = this.purchaserateperbox * this.taxpercentage / 100;
    this.taxvaltotqty = this.purchpricetotal * this.taxpercentage / 100;
    this.boxdisplaybaseqty = this.unit_qty_inp * this.purchsub_qty;

  }

  updateTaxPerc(taxCatId) {
    const selTax = this.taxCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
    this.taxpercentage = selTax[0].taxcat_tax_per;
    if(this.cmp_tax==2){
      this.cgst_perc = selTax[0].cgst_tax_per;
      this.sgst_perc = selTax[0].sgst_tax_per;
    }
    this.basicRates();
  }



  // purchase no. generation
  getNextPurchaseQtn() {
    let searchval = new FormData();
    this.apiService.getNextPurchaseQtn(searchval).subscribe((res) => {
      this.purchaseno = res['data'].purch_num;
      this.branch_qt_no = res['data'].pq_branch_qt_no;
      this.purchasedate = new Date();
      this.minDate=this.purchasedate;

      this.reference = '';
      this.confirmation_date = null;
      this.delivery_date = null;
      // this.purchase_types = [
      //   { id: 1, name: 'Standard Rated Domestic' },
      //   { id: 2, name: 'Zero Rated Domestic' },
      //   { id: 3, name: 'Exempt Purchase' },
      //   { id: 4, name: 'Imports Vat Paid to Customs' },
      //   { id: 5, name: 'Imports Vat - reverse charge mechanism' }
      // ];
    });
    this.getPurchSetting();


  }
  purchasedatechange(date)
  {
    this.minDate=date;
  }

  selectVat(ptypeid) {
    // different purchase types
    // { id: 1, name: 'Standard Rated Domestic' },
    // { id: 2, name: 'Zero Rated Domestic' },
    // { id: 3, name: 'Exempt Purchase' },
    // { id: 4, name: 'Imports Vat Paid to Custom' },
    // { id: 5, name: 'Imports Vat - reverse change mechanism' }
    this.purch_type_taxcat_id = 0;
    this.prd_tax_cat_id = 0;
    this.purch_tax_ledger_id = 0;
    if (ptypeid === 4 || ptypeid === 5) {
      // to set tax category international only
      this.taxListCategories = this.taxCategories.filter((taxCat) => Number(taxCat.is_international) == 1);
      if (this.taxListCategories.length > 0) {
        this.prd_tax_cat_id = this.purch_type_taxcat_id = this.taxListCategories[0].taxcat_id;
      }
    } else if (ptypeid === 3) {
      // to set tax category with 0% tax percentage
      this.taxListCategories = this.taxCategories.filter((taxCat) => Number(taxCat.taxcat_tax_per) == 0);
      if (this.taxListCategories.length > 0) {
        this.prd_tax_cat_id = this.purch_type_taxcat_id = this.taxListCategories[0].taxcat_id;
      }
    } else {
      this.taxListCategories = this.taxCategories;
    }

    if (ptypeid === 2 || ptypeid === 3) {
      this.p_type_id = 1;
      this.prd_tax_cat_id = 3;
    } else {
      this.p_type_id = 0;
    }
    const selTax = this.taxCategories.filter((taxCat) => Number(taxCat.taxcat_id) == this.prd_tax_cat_id);
    if (selTax.length > 0) {
      this.taxpercentage = selTax[0].taxcat_tax_per;
    }

    this.purchType = ptypeid;
  }

  getPurchaseProd(id, content) {
    if(this.multipleCurrency && !this.supp_id&&this.supp_acc_type==2){
      if (id) {
        this.coreService.showMessage("Please Choose Supplier First");
        setTimeout(() => {
          //  this.stkprd_all = [];
          this.stkprdsel = '';
        }, 1000);
      }
    } else {
    if (id) {
      this.productSearchLoader = true;
      let searchval = new FormData();
      searchval.append("prd_id", id);
      this.apiService.getPurchaseProduct(searchval).subscribe((res) => {
        if (res['data']) {
          this.selectStockPrdSel(res['data'], content);
          this.searchResult = '';
          this.allCurrency();

        } else {
          this.productSearchLoader = false;
          this.searchResult = 'Not found';
        }

      });
    }
  }
}
  purchaseRate(baserate: any, event) {
    this.baserate = Number(baserate);
    this.basicRates();
  }

  selectStockPrdSel(res: any, content) {

    if (res && res != "Select Product") {

  
      this.selecTed = res;
     
      this.purchsub_qty = '';
      

      if (this.purchType == 1) {
        this.prd_tax_cat_id = this.selecTed.prd_tax_cat_id;
      } else if (this.purchType == 2) {
        this.prd_tax_cat_id = 3;
      } else {
        this.prd_tax_cat_id = this.purch_type_taxcat_id;
      }
      // this.prd_tax_cat_id = this.selecTed.prd_tax_cat_id;
      const selTax = this.taxCategories.filter((taxCat) => Number(taxCat.taxcat_id) == this.prd_tax_cat_id);

      
      if (selTax.length > 0) {
        this.taxpercentage = selTax[0].taxcat_tax_per;
      } else {
        alert('Failed, Please Select Tax Category First');
        return false;
      }


      this.baseUnitRate = this.selecTed.purchase_rate;
      this.currentStock =this.selecTed.current_stock;
      this.prd_base_unit_name = res['prd_base_unit_name'];
      // this.prd_base_unit_name_master = res['prd_base_unit_name_master'];
      this.purchsub_unit_id = res['prd_base_unit_id'];
      // this.stkunitsel = res['prd_base_unit_name_master'];
      this.stkunitsel = res['prd_base_unit_name_bcode'];

      if(res['prd_def_unit_id']){
        this.prd_base_unit_name_master = res['prd_def_unit_name_master'];
        this.stkunitsel = this.prd_base_unit_name_master;
        this.unit_qty = this.selecTed.def_unit_det.unit_base_qty;
        this.selecTed.purchase_rate = this.baseUnitRate * this.selecTed.def_unit_det.unit_base_qty;
        this.purchaseBox(this.selecTed.def_unit_det)
      }else {
        this.unit_qty = 1;
      }
      this.baserate = Number(this.selecTed.purchase_rate);
      if(this.multipleCurrency && this.supp_default_currency_id != 0 && this.selcurrency){
      this.multibaserate=  Number(this.selecTed.purchase_rate / this.exchange_rate);
      }

      if (this.unit_qty != 1) {   
        this.boxname = this.prd_base_unit_name;
      }
      else {
        this.boxname = "nil";
      }

      this.modalRef = this.modalService.open(content, { size: 'lg' });
      this.modalRef.result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
      // });

      this.productSearchLoader = false;


    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  addPurch(event, purchase, addPurchase) {
    if (event.keyCode == 13) {
      this.updatePurchase(addPurchase);
    }

  }


  updatePurchase(list) {

    this.basicRates();

    this.validateForm();
    if(this.cmp_tax==2){
      this.updateTaxPerc(this.prd_tax_cat_id);
    }
    if (this.printError.length <= 0) {
      $('#err_dp').hide();

      this.resDup = this.coreService.checkItem(this.items, this.selecTed.prd_id, this.purchaserateperbox, this.purchsub_unit_id, this.item_desc);
      var existingArray = this.coreService.findItemPurchase(this.items, this.selecTed.prd_id, this.purchaserateperbox, this.purchsub_unit_id, this.item_desc);

      if (this.resDup && existingArray.length > 0) {
        console.log('helo');
        var existQty = this.items.find(x => x.sl_no === existingArray[0].sl_no).purchsub_qty;
        var existPrc = this.items.find(x => x.sl_no === existingArray[0].sl_no).purch_price;
        this.taxvaltotqty = ((Number(existQty) + Number(this.totalqty)) * this.taxvalperqty);
        this.items.find(x => x.sl_no === existingArray[0].sl_no).purchsub_qty = Number(existQty) + Number(this.totalqty);
        this.items.find(x => x.sl_no === existingArray[0].sl_no).purch_price = Number(existPrc) + Number(this.purchpricetotal);
        this.items.find(x => x.sl_no === existingArray[0].sl_no).purchsub_tax = this.taxvaltotqty;
        this.purchpricetotal = this.items.find(x => x.sl_no === existingArray[0].sl_no).purch_price;
        this.items.find(x => x.sl_no === existingArray[0].sl_no).boxdisplaybaseqty = this.items.find(x => x.sl_no === existingArray[0].sl_no).boxdisplaybaseqty + this.boxdisplaybaseqty;
        this.items.find(x => x.sl_no === existingArray[0].sl_no).boxqty = this.items.find(x => x.sl_no === existingArray[0].sl_no).boxqty + this.boxqty;
        // calculate frieght  


      } else {
        
        if (this.purchsub_gd_id == undefined)
          this.purchsub_gd_id = 0;
        this.slno = (this.items.length) + 1;
        console.log(this.items);
        
        this.items.push({
          "sl_no": this.slno, 'purchsub_prd_id': this.selecTed.prd_id, 'prd_name': this.selecTed.prd_name,'prd_alias': this.selecTed.prd_alias, 'ean': this.selecTed.ean, 'purchsub_stock_id': this.selecTed.cmp_stock_id, 'purchsub_qty': this.totalqty,
          'purchsub_rate': this.purchaserateperbox, 'purchsub_tax': this.taxvaltotqty, 'purchsub_tax_per': this.taxpercentage, 'purchsub_price': this.purchpricetotal,
          'purchpricesingle': this.purchprice, 'purchsub_gd_id': this.purchsub_gd_id,
          'manufacture_date': (list.value.manufacture_date) ?
            this.apiService.formatDate(list.value.manufacture_date) : list.value.manufacture_date, 'purchaserateperbox': this.purchaserateperbox, 'purch_price': this.purchpricetotal,
          'boxname': this.boxname, 'taxvalperqty': this.taxvalperqty, 'taxpercentage': this.taxpercentage,
          'purchsub_exchange_rate': (this.selcurrency && this.exchange_rate) ? this.exchange_rate : 1,
          'purchsub_currency_id': (this.selcurrency && this.exchange_rate) ? this.selcurrency.cur_id : 0,
          'boxdiplayname': this.boxdiplayname, 'boxdisplaybaseqty': this.boxdisplaybaseqty,
          'prd_base_unit_name': this.prd_base_unit_name, 'purchsub_unit_id': this.purchsub_unit_id, 'boxqty': this.boxqty,
          'prd_barcode': this.selecTed.prd_barcode, 'prd_tax_cat_id': this.prd_tax_cat_id, 'item_desc': this.item_desc,'sgst_perc': this.sgst_perc,'cgst_perc': this.cgst_perc
        });

      }
      this.is_description = this.items.filter(x => x.item_desc !== '');

      // end existing array
      this.modalRef.close();
      list.reset();
      $('.save').show();
      $('.box').show();
      this.refreshAll();
      this.puchaseListTable = true;
      if (this.srch_mtd == 2) {
        this.inputItemId.nativeElement.focus();
      } else {
        this.selectproductId.focus();
      }



    }

  }

  deleteProd(data) {
    var remopstk = confirm("Removing Selected Product from the table");
    if (remopstk) {
      var type = data.sl_no,
        i;
      for (i = this.items.length - 1; i >= 0; --i) {
        if (this.items[i].sl_no == type) {
          this.items.splice(i, 1);
        }
      }

      this.totpurchprice = Number(this.totpurchprice) - Number(data.purch_price);
      this.purch_tax = Number(this.purch_tax) - Number(data.purchsub_tax);


      if (this.items.length == 0) {
        this.puchaseListTable = false;
      }
    }

  }


  validateForm() {
    this.printError = [];
    if (this.unit_qty == "" || this.unit_qty == undefined) {
      this.printError.push({ 'unitqtyErr': 'Required' });
      $('#purchaseunit').css("border", "1px solid red");
    }
    else {
      $('#purchaseunit').css("border", "1px solid #dedede");
    }
    if (this.purchsub_qty == "" || this.purchsub_qty <= 0) {
      this.printError.push({ 'qtyErr': 'Required' });
      $('#purchaseqty').css("border", "1px solid red");
    }
    else {
      $('#purchaseqty').css("border", "1px solid #dedede");
    }

    if (this.baserate < 0) {
      this.printError.push({ 'prateErr': 'Required' });
      $('#basicrate').css("border", "1px solid red");
    } else {
      $('#basicrate').css("border", "1px solid #dedede");
    }



  }

  purchaseQty(purchaseqty: any, event) {
    this.basicRates();
  }

  basicRates() {
    //  calculate single rate
    // this.singlerate = this.baserate / (this.purchsub_qty * this.unit_qty);
    // this.baserate = Number(this.selecTed.purchase_rate);
    //   change tax amount
    // this.taxpercentage = $('#tax').val();
  
    if(this.multipleCurrency && this.supp_default_currency_id != 0 && this.selcurrency){
     
      
     this.baserate=  Number(this.multibaserate *this.exchange_rate);
    this.multibaseratetax = Number( (this.baserate * this.taxpercentage)/100);
    this.multitaxamount=Number(this.multibaseratetax/ this.exchange_rate);
    }
    this.taxamount = (this.baserate * this.taxpercentage) / 100;
    console.log('this.unit_qty');
    console.log(this.unit_qty);
    this.totalqty = this.purchsub_qty * this.unit_qty;
    console.log('this.totalqty');
    console.log(this.totalqty);
    
    this.purchprice = this.baserate;
    this.taxamounttotal = this.totalqty * this.taxamount;
    this.purchaserateperbox = this.purchprice / this.unit_qty;
    this.purchpricetotal = this.purchaserateperbox * this.totalqty;
    this.boxqty = this.totalqty / this.unit_qty;
    this.taxvalperqty = this.purchaserateperbox * this.taxpercentage / 100;
    this.taxvaltotqty = this.purchpricetotal * this.taxpercentage / 100;
    this.boxdisplaybaseqty = this.unit_qty * this.purchsub_qty;
  }

  getPurchSetting() {

    this.apiService.getPurchSetting().subscribe((res) => {
      if (res['data']) {
        this.settings = res['data'];
        this.purch_pay_type = this.settings.ps_pay_type;
        this.purchtypesel = this.settings.ps_purch_type;
        this.srch_mtd = this.settings.ps_srch_mtd;

      } else { // Default values if not setted
        this.purch_pay_type = 1;
        this.purchtypesel = 1;
        this.srch_mtd = 1;

      }
      if (this.srch_mtd == 2) {
        this.inputItemId.nativeElement.focus();
      } else {
        this.selectproductId.focus();
      }
      this.selectVat(this.purchtypesel);
    });

  }



  refreshAll() {
    this.totalqty = 0;
    this.taxamounttotal = 0;
    this.taxamount = '';
    this.taxpercentage = 0;
    this.baserate = 0;
    this.unit_qty = 0;
    this.unit_qty_inp = 0;
    this.purchsub_qty = '';
    this.totpurchprice = 0;
    this.purch_tax = 0;
    this.purch_cgst = 0;
    this.purch_sgst = 0;
    this.purch_igst = 0;
    console.log(this.items);
    
    for (var i = 0; i < this.items.length; i++) {
      this.totpurchprice = this.totpurchprice + Number(this.items[i]['purch_price']);
      this.purch_tax = this.purch_tax + Number(this.items[i]['purchsub_tax']);
      if(this.cmp_tax==2){
        this.purch_cgst = this.purch_cgst + Number(this.items[i]['purch_price']/100)*this.items[i]['cgst_perc'];
        this.purch_sgst = this.purch_sgst + Number(this.items[i]['purch_price']/100)*this.items[i]['sgst_perc'];
      console.log(this.items[i]['purch_price']);
      console.log(this.items[i]['cgst_perc']);
      console.log(this.items[i]['sgst_perc']);
      
      }
  
    }
    // for IGST Case
   if((this.cmp_tax==2)){

    this.purch_igst=this.purch_tax;

   }
    this.purchpricetotal = 0;
    this.purchprice = 0;

    this.unit_qty = '';
    this.stkunitsel = '';


    this.stkunitsel = "Select Unit";
    this.sel_gd_id = 0;
    this.stkprdsel = "Select Product";
    this.stkprd_all = [];
    this.boxdiplayname = '';
    this.boxdisplaybaseqty = null;
    this.prd_base_unit_name = null;
    this.purchsub_unit_id = null;
    this.item_desc = '';
    this.item_barcode = '';



  }

  resetPurchase() {
    this.update = false;
    this.puchaseListTable = false;
    this.items = [];
    this.refreshAll();
    this.getNextPurchaseQtn();
    this.round_sign = 1;
    this.roundoff = 0.00;

    this.is_listing = false;


    this.resultobj = {};
    
    this.puchaseListTable = false;
    this.items = [];
    this.totpurchprice = 0;
    this.purch_amount = 0;
    this.purch_tax = 0;
    this.date = new Date();
    this.serializedDate = new FormControl((new Date()).toISOString());
    this.discount = 0;
    this.purchType = 1;
    this.supp_acc_type = 2;
    this.supp_id = null;
    this.supp_new_name = null;
    this.supp_address = null;
  }


  purchase_add(formdata: { value: any; }) {

    this.printError = [];

    if (formdata.value.purch_date == undefined) {
      this.errObjArr.dtErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      if (formdata.value.purch_date > new Date()) {
        this.errObjArr.dtErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      } else {
        this.errObjArr.dtErr = "f";
      }
    }

    if (formdata.value.purch_round_sign == undefined || formdata.value.purch_round_sign == '') {
      this.errObjArr.purch_round_sign = "t";
      this.printError.push({ 'purch_round_sign': 'Required' });
    } else {
      this.errObjArr.purch_round_sign = "f";
    }
    if (this.roundoff == undefined || this.roundoff === '') {
      this.errObjArr.purch_roundoff = "t";
      this.printError.push({ 'purch_roundoff': 'Required' });
    } else {
      this.errObjArr.purch_roundoff = "f";
    }


    if (this.printError.length <= 0) {


      formdata.value.purch_type = this.purchType;

      if (formdata.value.purch_tax == null)
        formdata.value.purch_tax = 0;
      // var confrm = confirm("Are you sure?");
      if (confirm("Are you sure?")) {
        this.purchpageLoadingImg = true;
        formdata.value.items = this.items;
        if (formdata.value.purch_discount == null || formdata.value.purch_discount == "")
          formdata.value.purch_discount = 0;
        if (formdata.value.purch_tax_ledger_id == null && formdata.value.purch_tax_ledger_id == undefined) {
          formdata.value.purch_tax_ledger_id = 0;
        }


        formdata.value.purch_date = (formdata.value.purch_date) ?
          this.apiService.formatDate(formdata.value.purch_date) : formdata.value.purch_date;
        formdata.value.purch_inv_date = (formdata.value.purch_inv_date) ?
          this.apiService.formatDate(formdata.value.purch_inv_date) : formdata.value.purch_inv_date;
        formdata.value.terms = this.terms;  


        formdata.value.confirmation_date = (formdata.value.confirmation_date) ?
        this.apiService.formatDate(formdata.value.confirmation_date) : formdata.value.confirmation_date;

        formdata.value.delivery_date = (formdata.value.delivery_date) ?
        this.apiService.formatDate(formdata.value.delivery_date) : formdata.value.delivery_date;

        if(this.cmp_tax==2){
          formdata.value.total_cgst=this.purch_cgst;
          formdata.value.total_sgst=this.purch_sgst;
          
          if((this.cmp_tax==2)&&(this.igst)){

            formdata.value.total_igst=this.purch_igst;
            formdata.value.pq_is_igst=1;
          }
        }
        this.apiService.addPurchQuatation(formdata.value).subscribe((res: any) => {
          this.purchpageLoadingImg = false;

          if (res.error != null) {
            this.resultobj = res.error;
            this.resultobj = this.coreService.getValidation(this.resultobj);
          }
          else {

            this.previewData = res.preview;
            this.is_prew_description = this.previewData['items'].filter(x => x.pqsub_item_desc);

            setTimeout(function () { $("#previewButton").click(); }, 2000);
            this.is_listing = false;


            this.resultobj = {};
            this.coreService.showMessage('Purchase Saved');
            this.puchaseListTable = false;
            this.items = [];
            this.igst=false;
            this.refreshAll();
            this.getNextPurchaseQtn();
            this.totpurchprice = 0;
            this.purch_amount = 0;
            this.purch_tax = 0;
            this.date = new Date();
            this.serializedDate = new FormControl((new Date()).toISOString());
            this.discount = 0;
            this.purchType = 1;
            this.supp_acc_type = 2;
            this.supp_id = null;
            this.supp_new_name = null;
            this.supp_address = null;
            this.terms = '<div style="text-align: center;"><span style="background-color: transparent;"><b><u>Terms &amp; Conditions</u></b></span></div><div style="text-align: center;"><span style="background-color: transparent;"><b><u><br></u></b></span></div><div style="text-align: left;"><span style="background-color: transparent;">1.</span></div>';
            // setTimeout(function () {
            this.round_sign = 1;
            //   this.roundoff = 0;
            // }, 2000);
            this.roundoff_sign = [
              { value: 1, name: '+', selecetd: true },
              { value: -1, name: '-' }

            ];
            // this.round_sign = 1;
            $('#purch_roundoff').val('0');
            // $('#purch_round_sign').val('1');
            this.roundoff = '0';
            this.listPurchase(1);



          }
        });
      }
    }
  }

  closePreview() {
    // this.supplierSelect.focus();
    this.previewData = [];
  }

  showList(val) {
    this.resetPurchase();
    if (val)
      this.listing = false;
    else
      this.listing = true;

  }

  listPurchase(pageNo = 1) {
    let searchval = new FormData();
    // if(keyword)
    // searchval.append("keyword", keyword);
    this.apiService.getPurchaseQtnList(searchval, pageNo).subscribe((res) => {
      this.list_purchase = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  quatationPurchase(id, type) {
    this.previewLoader = true;
    const searchval = new FormData();
    searchval.append('pq_no', id);
    this.apiService.getpurchQtnDetails(searchval).subscribe((res) => {
      this.previewData = res.data;
      this.is_prew_description = this.previewData['items'].filter(x => x.pqsub_item_desc);
      setTimeout(function () {
        $("#previewButton").click();
      }, 1000);
      this.previewLoader = false;
      if (type === 'l') {
        this.is_listing = true;
      } else {
        this.is_listing = false;

      }

    });
  }

  editPO(id) {
    this.previewLoader = true;
    const searchval = new FormData();
    searchval.append('pq_no', id);
    this.apiService.getpurchQtnDetails(searchval).subscribe((res) => {
      this.previewLoader = false;
      this.editPurchase(res.data);
    });

  }
  editPurchase(data) {
    this.listing = false;
    $('.close').trigger("click");
    this.puchaseListTable = true;

    this.purchaseno = data.pq_no;
    this.datevar = this.datePipe.transform(data.pq_date, 'dd/MM/yyyy');
    var to = this.datevar.split("/");
    this.purchasedate = new Date(to[2], to[1] - 1, to[0]);
    this.supp_acc_type = data.pq_supp_type;
    // this.supp_id = data.pq_supp_id;

    this.supp_id = (data.pq_supp_id) ? Number(data.pq_supp_id) : null;
    if (this.supp_id) {
      this.getSupBalance(this.supp_id);
     
    }
    if(this.cmp_tax==2){
      this.cust_state_id = data.supp_state_id;
      this.iGSTchecking();
}
    this.supp_new_name = data.pq_supp_name;
    this.supp_address = data.pq_supp_address;
    this.branch_qt_no = data.pq_branch_qt_no;
    this.purchtypesel = data.pq_purch_type;
    this.purch_type_taxcat_id = data.pq_purch_type;
    this.terms = data.pq_terms;
    this.reference = data.pq_reference;
    this.confirmation_date = data.pq_confirmation_date ? new Date(data.pq_confirmation_date): null;
    this.delivery_date = data.pq_delivery_date ? new Date(data.pq_delivery_date) : null;
    this.items = [];
    data.items.forEach((element, index) => {
      this.items.push({
        "sl_no": index + 1,
        'purchsub_prd_id': element.pqsub_prd_id,
        'prd_name': element.prd_name,
        'prd_alias': element.prd_alias,
        'ean': null,   
        'purchsub_stock_id': element.pqsub_stock_id,
        'purchsub_qty': element.pqsub_qty,
        'purchsub_rate': element.pqsub_rate,
        'purchsub_tax': element.pqsub_tax * element.pqsub_qty,
        'purchsub_tax_per': element.pqsub_tax_per,
        'purchsub_price': element.pqsub_qty * element.pqsub_rate,
        'purchpricesingle': element.pqsub_rate,
        'purchsub_gd_id': element.pqsub_gd_id,
        'manufacture_date': null,
        'batch_code': null,
        'purchaserateperbox': element.pqsub_rate,
        'purch_price': element.pqsub_qty * element.pqsub_rate,
        'boxname': 'nil',
        'taxvalperqty': element.pqsub_tax,
        'taxpercentage': element.pqsub_tax_per,
        'cgst_perc': element.cgst_perc,
        'sgst_perc': element.sgst_perc,
        'boxdiplayname': element.unit_display,
        'boxdisplaybaseqty': element.pqsub_qty,
        'prd_base_unit_name': (element.unit_base_qty != 1 ? element.baseunit.unit_code : ''),
        'purchsub_unit_id': element.baseunit.unit_id,
        'boxqty': element.boxqty,
        'purchsub_exchange_rate': element.pqsub_exchange_rate,
        'purchsub_currency_id': element.pqsub_currency_id,
        'prd_barcode': element.prd_barcode,
        'prd_tax_cat_id': element.prd_tax_cat_id,
        'item_desc': element.pqsub_item_desc
      });

    });
    this.is_description = data.items.filter(x => x.pqsub_item_desc !== '');


    this.comments = data.pq_note;
    this.totpurchprice = data.pq_amount;
    this.purch_tax = data.pq_tax;
    if(this.cmp_tax==2){
      this.purch_cgst = data.pq_cgst;
      this.purch_sgst = data.pq_sgst;
      this.purch_igst = this.purch_tax;
      this.refreshAll();
    }
    this.discount = data.pq_discount;
    this.round_sign = (data.pq_roundoff && data.pq_roundoff < 0) ? -1 : 1;
    this.roundoff = Math.abs(data.pq_roundoff);
    this.update = true;

  }

  purchaseUpdate(formdata: { value: any; }) {

    this.printError = [];

    if (formdata.value.purch_date == undefined) {
      this.errObjArr.dtErr = "t";
      this.printError.push({ 'fieldErr': 'Required' });
    }
    else {
      if (formdata.value.purch_date > new Date()) {
        this.errObjArr.dtErr = "t";
        this.printError.push({ 'fieldErr': 'Required' });
      } else {
        this.errObjArr.dtErr = "f";
      }
    }

    if (formdata.value.purch_round_sign == undefined || formdata.value.purch_round_sign == '') {
      this.errObjArr.purch_round_sign = "t";
      this.printError.push({ 'purch_round_sign': 'Required' });
    } else {
      this.errObjArr.purch_round_sign = "f";
    }
    if (this.roundoff == undefined || this.roundoff === '') {
      this.errObjArr.purch_roundoff = "t";
      this.printError.push({ 'purch_roundoff': 'Required' });
    } else {
      this.errObjArr.purch_roundoff = "f";
    }


    if (this.printError.length <= 0) {


      formdata.value.purch_type = this.purchType;

      if (formdata.value.purch_tax == null)
        formdata.value.purch_tax = 0;
      // var confrm = confirm("Are you sure?");
      if (confirm("Are you sure?")) {
        this.purchpageLoadingImg = true;
        formdata.value.items = this.items;
        if (formdata.value.purch_discount == null || formdata.value.purch_discount == "")
          formdata.value.purch_discount = 0;
        if (formdata.value.purch_tax_ledger_id == null && formdata.value.purch_tax_ledger_id == undefined) {
          formdata.value.purch_tax_ledger_id = 0;
        }


        formdata.value.purch_date = (formdata.value.purch_date) ?
          this.apiService.formatDate(formdata.value.purch_date) : formdata.value.purch_date;
        formdata.value.purch_inv_date = (formdata.value.purch_inv_date) ?
          this.apiService.formatDate(formdata.value.purch_inv_date) : formdata.value.purch_inv_date;
        formdata.value.terms = this.terms;

        formdata.value.confirmation_date = (formdata.value.confirmation_date) ?
        this.apiService.formatDate(formdata.value.confirmation_date) : formdata.value.confirmation_date;

        formdata.value.delivery_date = (formdata.value.delivery_date) ?
        this.apiService.formatDate(formdata.value.delivery_date) : formdata.value.delivery_date;
        
        if(this.cmp_tax==2){
          formdata.value.total_cgst=this.purch_cgst;
          formdata.value.total_sgst=this.purch_sgst;

          if((this.cmp_tax==2)&&(this.igst)){

            formdata.value.total_igst=this.purch_igst;
            formdata.value.pq_is_igst=1;
          }
        }
        this.apiService.updatePurchaseQtn(formdata.value).subscribe((res: any) => {
          this.purchpageLoadingImg = false;

          if (res.error != null) {
            this.resultobj = res.error;
            this.resultobj = this.coreService.getValidation(this.resultobj);
          }
          else {

            this.previewData = res.preview;
            this.is_prew_description = this.previewData['items'].filter(x => x.pqsub_item_desc);

            setTimeout(function () { $("#previewButton").click(); }, 2000);
            this.is_listing = false;


            this.resultobj = {};
            this.coreService.showMessage('Purchase Saved');
            this.puchaseListTable = false;
            this.items = [];
            this.igst=false;
            this.refreshAll();
            this.getNextPurchaseQtn();
            this.totpurchprice = 0;
            this.purch_amount = 0;
            this.purch_tax = 0;
            this.date = new Date();
            this.serializedDate = new FormControl((new Date()).toISOString());
            this.discount = 0;
            this.purchType = 1;
            this.supp_acc_type = 2;
            this.supp_id = null;
            this.supp_new_name = null;
            this.supp_address = null;
            this.terms = '<div style="text-align: center;"><span style="background-color: transparent;"><b><u>Terms &amp; Conditions</u></b></span></div><div style="text-align: center;"><span style="background-color: transparent;"><b><u><br></u></b></span></div><div style="text-align: left;"><span style="background-color: transparent;">1.</span></div>';
            // setTimeout(function () {
            this.round_sign = 1;
            //   this.roundoff = 0;
            // }, 2000);
            this.roundoff_sign = [
              { value: 1, name: '+', selecetd: true },
              { value: -1, name: '-' }

            ];
            // this.round_sign = 1;
            $('#purch_roundoff').val('0');
            // $('#purch_round_sign').val('1');
            this.roundoff = '0';
            this.listPurchase(1);



          }
        });
      }
    }
    this.update = false;
  }

  getLowStockProds() {

    this.apiService.getLowStockProds().subscribe((res) => {
      this.lowStockItems = res['data'];
    });

  }

  getPurchQtnList(keyword) {
    let searchval = new FormData();
    if(keyword)
    searchval.append("keyword", keyword);
    this.apiService.getPurchaseQtnList(searchval, 1).subscribe((res) => {
      this.list_purchase = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {
      // console.log(res);

      this.savedTemplate = {
        
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_height: res.data.prnt_nt_header_height,
        footer_height: res.data.prnt_nt_footer_height,
        page_no_display: res.data.prnt_nt_display_page_no,
        left_h: res.data.prnt_nt_left_space,
        botom_h: res.data.prnt_nt_bottom_space,
        page_format: res.data.prnt_nt_page_no_disply_frmt
      }
      if (res.data.prnt_header_logo) {
      this.logoImgUrl = this.apiService.PHP_API_SERVER+'/'+res.data.prnt_header_logo_img_path;
    } else {
      this.logoImgUrl = '';
    }
      
      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER+'/'+res.data.prnt_header_img_path;
        this.footImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_footer_img_path;
      } else {
        this.tempImgUrl = '';
        this.footImgUrl = '';
      }

      if(res.data.water_mark_img_path){
        this.water_mark = this.apiService.PHP_API_SERVER + "/" + res.data.water_mark_img_path;
      } else {
        this.water_mark = "";
      }

    });
  }

  copypurchase(id) {
    console.log(id);
    this.route.navigate(['/purchase/purchase', id]);
}

langChange(){
  this.translate.get(['Common.standard_rated_domestic', 'Common.zero_rated_domestic', 'Common.exempt_sales', 'Common.imports_vat_paid_custms', 'Common.imports_vat_rvs_chage_mechnsm','Common.new','Common.registerd']).subscribe((res: string) => {    
    
    this.purchase_types = [
      { id: 1, name: res['Common.standard_rated_domestic']},
      { id: 2, name: res['Common.zero_rated_domestic'] },
      { id: 3, name: res['Common.exempt_sales'] },
      { id: 4, name: res['Common.imports_vat_paid_custms'] },
      { id: 5, name: res['Common.imports_vat_rvs_chage_mechnsm'] },
    ];

    this.acc_types = [
      { id: 1, name: res['Common.new']},
      { id: 2, name: res['Common.registerd'] },

    ];
  });

  
}


barcodeSearch(search: string, content) {
  if(this.multipleCurrency && !this.supp_id&&this.supp_acc_type==2){
    if (search) {
      this.coreService.showMessage("Please Choose Supplier First");
      setTimeout(() => {
        // this.stkprd_all = [];
        this.item_barcode = '';
      }, 1000);
    }
  }else{
  if (search) {
    let searchval = new FormData();
    searchval.append("keyword", search);
    this.apiService.getPurchasebyBcode(searchval).subscribe((res) => {
      this.stkprdbc_all = res['data'];
      if (this.stkprdbc_all.length>0) {


        this.selectStockPrdSel(res['data'][0], content);
        this.searchResult = '';  
     
      
      } else {  

        this.barcodeSearchUean(search, content);

      }

    });
  }
}
}
barcodeSearchUean(search: string, content) {

  let searchval = new FormData();
  searchval.append("keyword", search);
  this.apiService.getPurchasebyUean(searchval).subscribe((res) => {

    this.stkprdbc_all = [];
    this.stkprdbc_all = res['data'];
    if (this.stkprdbc_all.length > 0) {
      this.selectStockPrdSel(res['data'][0], content);
      this.searchResult = '';  
    } else {
      this.searchResult = 'No items found';
    }

  });
}

removepurchasependingitem(id) {

  if (confirm("Do you wish to void this entry?")) {
    let searchval = new FormData();

    searchval.append("pq_no", id);
    this.apiService.voidpurchaseorderitem(searchval).subscribe((res) => {
      if (res.message)
        this.coreService.showMessage('Voided Successfully');
        // this.listing = true;
        // this.listQtn(1);
        this.listPurchase(1);
    });

  }

}

supplierdetails(sup_id){

  if(sup_id){
    
    if(this.cmp_tax==2){
      console.log(this.cust_state_id);
      console.log(this.branch_state);
      console.log(this.supp_id);
      this.apiService.getSupplierDetails({ supp_id: sup_id }).subscribe((res) => {
        this.supp_details=res.data;
       
        if(this.supp_details){
          this.cust_state_id = this.supp_details.supp_state_id;
          this.iGSTchecking();
        }
        });
   
      }
    }
 
  }
  
  iGSTchecking(){
      
    if(this.cmp_tax==2){
      console.log("this.cust_state_id2");
     console.log(this.cust_state_id);
     console.log(this.branch_state);
     console.log(this.supp_id);
     
     
     
      // for is igst verify
      if((this.cust_state_id!=this.branch_state)&&(this.cust_state_id)&&(this.supp_id)){
        this.igst=true;
      }else{
        this.igst=false;
      }
     
      }
  }

}
