import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { MatStepper } from '@angular/material/stepper';
import { ApiService } from '../../../../../service/api.service';
import { CoreService } from '../../../../../service/core.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NgSelectComponent } from '@ng-select/ng-select';
import { NgForm } from '@angular/forms';
import { ActivatedRoute  } from '@angular/router';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-delivery-challan',
  templateUrl: './delivery-challan.component.html',
  styleUrls: ['./delivery-challan.component.css']
})


export class DeliveryChallanComponent implements OnInit {

  @ViewChild('selectproductId', { static: false }) selectproductId: NgSelectComponent;
  @ViewChild('selectQty', { static: false }) selectQty: ElementRef;
  @ViewChild('selctbarcode', { static: false }) selctbarcode: ElementRef;
  @ViewChild('selctRecAmnt', { static: false }) selctRecAmnt: ElementRef;
  @ViewChild('step1', { static: false }) formStep1: NgForm;
  @ViewChild('stepper', { static: false }) mainStepper: MatStepper;
  @ViewChild('firstnextButton', { static: false }) firstnextButton: ElementRef;
  @ViewChild('secndnextButton', { static: false }) secndnextButton: ElementRef;


  
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '350px',
    minHeight: '350px',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'http://127.0.0.1:8000/api/saless/upimgs',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  };

 
  customer_dt = {
    id: '',
    ledger_id: '',
    name: '',
    alias: '',
    code: '',
    email: '',
    mobile: '',
    vat_no: '',
    op_bal: 0,
    brch_id: 0,
    usr_type: 0,
    is_supplier: false,
    op_bal_type: true,
    add: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    del: {
      addr: '',
      zip: '',
      city: '',
      country: '',
      state: '',
      state_code: '',
      fax: ''
    },
    due_day: '',
    van_line: 0,
    price_group: 1,
    note: ''
  };
  resultobj: any = {};
  isSubmitInProg = false;
  userType: any;
  // terms = '<div style="text-align: center;"><span style="background-color: transparent;"><b><u>Terms &amp; Conditions</u></b></span></div><div style="text-align: center;"><span style="background-color: transparent;"><b><u><br></u></b></span></div><div style="text-align: left;"><span style="background-color: transparent;">1.</span></div>';
  terms = '';


  up: any;
  godown_all: any;
  stkprd_all: any;
  cust_types = [
    { id: 2, name: 'Registered' }
  ];

  vat_types = [
    { id: 1, name: 'Excl. Vat' },
    { id: 2, name: 'Incl. Vat' }
  ];

  item_disc_types = [
    { id: 1, name: 'Rate' },
    { id: 2, name: 'Percentage' }
  ];


  customer: any;
  cust_type: any;
  cust_code: any;
  cust_addr: any;
  cntct_num: any;
  vat_no: any;
  godownStocks: any;
  branchStocks: any;
  selctedProd: any;
  excl_vat: number;
  item_disc_type: any;
  taxProdCategories: any;
  taxpercentage: any;
  rate: any;
  prd_tax_cat_id: any;
  unit: any;
  selectedProdDetails: any;
  qty: any;
  itemDisc: any;
  purchAmnt: any;
  tax_amnt: any;
  taxprice: number;
  grnd_totl: any;
  disc_amnt: any;
  itemTotal: any;
  printError: any;
  gd_id: any;
  stkprdsel: any;
  base_qty: any;
  unit_name: any;
  salesItems = [];
  item_dicrptn: any;
  resDup: any;
  taxvaltotqty: number;
  totItemprice = 0;
  totItemDisc = 0;
  totVatAmnt = 0;
  totcgstAmnt = 0;
  totsgstAmnt = 0;
  totigstAmnt = 0;
  net_disc = 0;
  lastInvoice: any;
  cust_id: any;
  barcode: string;
  cust_name: string;
  saleqt_date: any;
  selectError: boolean;
  qt_no: string | Blob;
  dcRefNo: any;
  cmpny: any;
  valErrors = <any>{};

  savedTemplate = {
    title_text: 'SALES INVOICE فاتورة',
    color: '#000',
    email_color: '#000',
    font_size: '12',
    orientation: "P",
    paper: 'A4-P',
    m_left: '',
    m_top: '',
    m_right: '',
    m_bottom: '',
    logo: false,
    header_type: 1,
    header_img: [],
    header_all_page: false,
    header_due: false,
    sl_no: false,
    item_desc: false,
    tax_detail: false,
    tax_type: 0,
    name_type: 'N',
    footer_text: '',
    header_height: 35,
    footer_height: 32,
    page_no_display: 0,
    left_h: 92.6,
    botom_h: 26.4,
    page_format: ''
  }
  logoImgUrl: string;
  tempImgUrl: string;
  saleData: any;
  itemRate: number;

  purchase_types = [
    { id: 1, name: 'Standard Rated Domestic' },
    { id: 2, name: 'Zero Rated Domestic' },
    { id: 3, name: 'Exempt Sales' }
  ];
  purch_type_taxcat_id: any;
  taxListCategories: any;
  purchtypesel: any;
  zeroType: boolean;
  godownStocksTotal = 0;
  branchStocksTotal = 0;
  lastCust = 0;
  draftLoader: boolean;
  draftList: any;
  due_date: any;
  sale_agent: any;
  sqdrf_id: any;
  listing: boolean;
  list_sales: any;
  pgend: boolean;
  pgstart: boolean;
  curpage: any;
  lastpage: any;
  from: any;
  importLoader: boolean;
  imporDrafttLoader: boolean;
  sq_inv_no: any;
  isEdit: boolean;
  sl_no: any;
  saleQtnData: any;
  previewLoader: boolean;
  del_chellan_note: any;

  salesPayType = [
    { id: 1, name: 'Credit' },
    { id: 2, name: 'Cash' },
    { id: 3, name: 'Bank / Card' }


  ];
  payTypes = ['Cash', 'Credit', 'Bank / Card'];
  defLedger: any;
  sale_pay_type: number;
  sale_acc_ledger_id: string;
  delivry_addr: any;
  valid_till_date: any;
  inv_amnt: number;
  recivd_amnt: number;
  bal_amnt: number;
  del_chellan_agents: any;
  is_description: any[];
  is_prew_description: any[];
  pageLoadingImg: boolean;
  editLogpreviewLoader: boolean;
  saleEditLog: any;
  editMessage: boolean;
  private modalRef: NgbModalRef;
  closeResult: string;
  noCustomer: boolean;

  saleData1:any[];
  previewLoader1 = false;
  qr_inv='';
  sales_inv_no : any;
  base_url: string;
  qtn_id:any;
  usr_hide_purch_cost:any;
  showQtnImpLoader = false;
  sq_branch_qt_no: any;
  qt_id: any;
  del_chellan_po_no: any;
  sales_settings: any;
  show_prd_code: any;
  withoutStock: any;
  sales_invoice_id: any;
  dflt_search_methd: any;
  matindex: number;
  footImgUrl:any;
  retVal: boolean;
  errorMessage: string;
  errorMessage1: string;
  del_chellan_with_stock: any;
  country_dec: string;
  prd_location: any;
  cmp_tax: any;
  igst: boolean=false;
  cgstpercentage: any;
  sgstpercentage: any;
  branch_state: any;
  states: any;
  cust_state_id: any;
  disable = false;
  available_stock: any;
  unit_available_stock: any;
  bs_prate :any;
  unit_bs_prate :any;
  withStock:any;
  reference: any;
  cur_lang: string;
  prod_alias: string;
  sply_st_id: any;
  constructor(private apiService: ApiService, private coreService: CoreService, private modalService: NgbModal,private ActiveRoute: ActivatedRoute,private translate: TranslateService,) { }


  ngOnInit() {
    this.up = this.coreService.getUserPrivilage();
    this.cur_lang = sessionStorage.getItem("baseLang");
this.aliasChecked()
  this.apiService.cur_lang.subscribe(value =>{
      this.cur_lang = value;
      this.aliasChecked()
    })

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.langChange();
    });
    this.langChange();
    this.cmp_tax = this.coreService.getUserData('cmpny_tax');
    this.base_url = this.apiService.PHP_API_SERVER;

    this.qtn_id = this.ActiveRoute.snapshot.paramMap.get('qtn_id');
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');

    if(this.cmp_tax==2){
      this.branch_state = this.coreService.getUserData('branch_state_id');
      this.getAllState();
    }
    if (this.qtn_id && !isNaN(this.qtn_id)){
      this.showQtnImpLoader = true;
     // console.log('qtn_id ' + this.qtn_id);

      let searchval = new FormData();
      searchval.append("sq_inv_no", this.qtn_id);

      this.apiService.getSalesQtn(searchval).subscribe((res: any) => {


          this.purchtypesel = res.data.sq_tax_type + 1;
          this.salesItems = [];

          if (res.data.sq_valid_till) {
            this.valid_till_date = new Date(res.data.sq_valid_till);
          } else {
            this.valid_till_date = '';
          }
          this.saleqt_date = new Date(res.data.sq_date);

          if (res.data.sq_cust_type == 0) {
            this.cust_type = 1;
          } else if (res.data.sq_cust_type == 1) {
            this.cust_type = 2;
          }

          if (res.data.sq_pay_type == 0) {
            this.sale_pay_type = 2;
          } else if (res.data.sq_pay_type == 1) {
            this.sale_pay_type = 1;
          } else if (res.data.sq_pay_type == 2) {
            this.sale_pay_type = 3;
          }

          if (res.data.sq_acc_ledger_id > 0) {
            this.defLedger = res.data.acc_ledger;
            this.sale_acc_ledger_id = res.data.sq_acc_ledger_id;
          }
          this.qt_id = res.data.sq_inv_no;
          this.sq_branch_qt_no = res.data.sq_branch_qt_no;
          this.cust_code = (res.data.customer ? res.data.customer.cust_code : '');
          this.cust_name = res.data.sq_cust_name;
          this.cust_id = res.data.customer;
          if(this.cmp_tax==2){
            this.cust_state_id = this.cust_id.state_id;
           this.iGSTchecking();
            }
          this.cust_addr = res.data.sq_cust_address;
          this.cntct_num = res.data.sq_cust_phone;
          this.vat_no = res.data.sq_cust_tin;
          this.net_disc = res.data.sq_disc;
          this.del_chellan_note = res.data.sq_notes;
          this.sale_agent = res.data.sq_sales_agent;
          this.reference = res.data.sq_reference;

          res.data.sales_qtn_sub.forEach((element, index) => {

            let tmp= <any>{};
            tmp.del_chellan_sub_prd_id = element.salesub_prd_id;
            tmp.sl_no = index +1;
            tmp.prd_name = element.prd_name;
            tmp.ean = element.ean;
            tmp.del_chellan_sub_stock_id = element.salesub_stock_id;
            tmp.del_chellan_sub_qty = element.salesub_qty;
            tmp.del_chellan_sub_rate = element.salesub_rate;
            tmp.del_chellan_sub_amnt = element.salesub_rate * element.salesub_qty;
            tmp.del_chellan_sub_tax_amnt = element.salesub_tax_amnt;
            tmp.del_chellan_sub_tax = element.salesub_tax;
            tmp.del_chellan_sub_tax_per = element.salesub_tax_per;
            tmp.del_chellan_sub_cgst_tax_per = element.salesub_cgst_tax_per;
            tmp.del_chellan_sub_sgst_tax_per = element.salesub_sgst_tax_per;
            tmp.del_chellan_sub_price = element.salesub_price;
            tmp.purchpricesingle = element.salesub_rate / element.base_qty;
            tmp.del_chellan_sub_gd_id = element.salesub_gd_id;
            tmp.taxvalperqty = element.salesub_tax / element.base_qty;
            tmp.base_qty = element.base_qty;
            tmp.prd_unit_name = element.prd_unit_name;
            tmp.del_chellan_sub_unit_id = element.salesub_unit_id;
            tmp.prd_barcode = element.prd_barcode;
            tmp.prd_tax_cat_id = element.prd_tax_cat_id;
            tmp.item_desc = element.item_desc;
            tmp.item_disc = element.item_disc;
            tmp.prd_type = element.prd_type;
            tmp.vat_type = element.vat_type;
            tmp.item_disc_type = element.item_disc_type;
            this.salesItems.push(tmp);
          });


          this.sumSaleTotal();
          this.changeAmnt();
          // this.calcRates();

          this.importLoader = false;

          this.listing = false;
          this.is_description = this.salesItems.filter(x => x.item_desc !== '');
          this.withoutStock =1;



        // this.previewLoader1 = false;
        this.showQtnImpLoader = false;
      });
    }

    this.sales_inv_no = this.ActiveRoute.snapshot.paramMap.get('sales_inv_no');
    this.usr_hide_purch_cost = this.coreService.getUserData('usr_hide_purch_cost');
    if (this.sales_inv_no && !isNaN(this.sales_inv_no)){
      this.showQtnImpLoader = true;
     // console.log('sales_inv_no ' + this.sales_inv_no);

      let searchval = new FormData();
      searchval.append("sales_inv_no", this.sales_inv_no);

     

      this.apiService.getConvertSalesDet(searchval).subscribe((res: any) => {
        if (res.data) {
  
          this.purchtypesel = res.data.sales_tax_type + 1;
          this.salesItems = [];
  
          res.data.sales_sub.forEach((element, index) => {
  
            let tmp= <any>{};
            tmp.del_chellan_sub_prd_id = element.salesub_prd_id;
            tmp.prd_name = element.prd_name;
            tmp.ean = element.ean;
            tmp.sl_no = index +1;
            tmp.del_chellan_sub_stock_id = element.salesub_stock_id;
            tmp.del_chellan_sub_qty = element.salesub_qty;
            tmp.del_chellan_sub_rate = element.salesub_rate;
            tmp.del_chellan_sub_amnt = element.salesub_rate * element.salesub_qty;
            tmp.del_chellan_sub_tax_amnt = element.salesub_tax_amnt;
            tmp.del_chellan_sub_tax = element.salesub_tax;
            tmp.del_chellan_sub_tax_per = element.salesub_tax_per;
            tmp.del_chellan_sub_price = element.salesub_price;
            tmp.purchpricesingle = element.salesub_rate / element.base_qty;
            tmp.del_chellan_sub_gd_id = element.salesub_gd_id;
            tmp.taxvalperqty = element.salesub_tax / element.base_qty;
            tmp.base_qty = element.base_qty;
            tmp.prd_unit_name = element.prd_unit_name;
            tmp.del_chellan_sub_unit_id = element.salesub_unit_id;
            tmp.prd_barcode = element.prd_barcode;
            tmp.prd_tax_cat_id = element.prd_tax_cat_id;
            tmp.item_desc = element.item_desc;
            tmp.item_disc = element.item_disc;
            tmp.prd_type = element.prd_type;
            tmp.vat_type = element.vat_type;
            tmp.item_disc_type = element.item_disc_type;
            tmp.entered_qty = element.delivery_challan_qty;
            tmp.balance_qty = element.balanceDeliveryChallan_qty;
            tmp.delivery_qty =  tmp.balance_qty;
            if(element.salesub_id)
            {
              tmp.salesub_id =  element.salesub_id;
            }
            if(this.cmp_tax==2){
              tmp.del_chellan_sub_cgst_tax_per=element.salesub_cgst_tax_per;
              tmp.del_chellan_sub_sgst_tax_per=element.salesub_sgst_tax_per;
            }
            this.salesItems.push(tmp);

            
            
          });

          

          if(this.cmp_tax==2){
            this.cust_state_id =  res.data.customer.state_id;
            this.iGSTchecking();
           
          }
          
  
          if (res.data.sales_due_date) {
            this.valid_till_date = new Date(res.data.sales_due_date);
          } else {
            this.valid_till_date = '';
          }
          // this.saleqt_date = new Date(res.data.sales_due_date);
          this.saleqt_date = new Date();
  
          if (res.data.sales_cust_type == 0) {
            this.cust_type = 1;
          } else if (res.data.sales_cust_type == 1) {
            this.cust_type = 2;
          }
  
          if (res.data.sales_pay_type == 0) {
            this.sale_pay_type = 2;
          } else if (res.data.sales_pay_type == 1) {
            this.sale_pay_type = 1;
          } else if (res.data.sales_pay_type == 2) {
            this.sale_pay_type = 3;
          }
  
          if (res.data.sales_acc_ledger_id > 0) {
            this.defLedger = res.data.acc_ledger;
            this.sale_acc_ledger_id = res.data.sales_acc_ledger_id;
          }
  
          this.cust_code = (res.data.customer ? res.data.customer.cust_code : '');
          this.cust_name = res.data.sales_cust_name;
          this.cust_id = res.data.customer;
          this.cust_addr = res.data.sales_cust_address;
          this.cntct_num = res.data.sales_cust_ph;
          this.vat_no = res.data.sales_cust_tin;
          this.net_disc = res.data.sales_discount;
          this.del_chellan_note = res.data.sales_notes;
          this.reference = res.data.sales_reference;
          this.sale_agent = res.data.sales_agent_ledger_id;
          // this.sale_agent = res.data.sqdrf_sales_agent;
          this.sales_invoice_id = this.sales_inv_no;
          this.sumSaleTotal();
          this.changeAmnt();
  
          this.importLoader = false;
  
          this.listing = false;
          this.is_description = this.salesItems.filter(x => x.item_desc !== '');
          this.withoutStock =1;
  
  
  
        }
        this.previewLoader1 = false;
      });
    }
    this.getGodownList();
    this.nextRefNo();
    this.cust_type = 2;
    this.excl_vat = 1;
    this.item_disc_type = 1;
    this.selectError = false;
    this.listing = false;
    this.importLoader = false;
    this.imporDrafttLoader = false;
    this.isEdit = false;
    this.sale_pay_type = 1;
    // this.withoutStock =1;
    this.disc_amnt = 0;
    this.apiService.getAllTaxCategory().subscribe((resp) => {

      this.taxProdCategories = resp.data.filter((taxCat) => Number(taxCat.is_international) == 0);
    });
    this.cmpny = this.coreService.getUserData('cmpny');
    this.gd_id = 0;
    this.purchtypesel = 1;
    this.getSalesSetting();
    this.getTemplete();
    this.getDraftList();
    this.listQtn(1);
    this.saleqt_date = new Date();
    this.is_description = [];
    this.is_prew_description = [];
    this.item_dicrptn = '';
    this.editMessage = false;
    this.userType = this.coreService.getUserData('user_type');
    

    this.country_dec=this.coreService.setDecimalLength();
    this.getAllAgents();
  }
  aliasChecked(){
    if(this.cur_lang !=undefined && this.cur_lang !=null && this.cur_lang !='English' ){
      this.prod_alias = 'alias_true';
      
    }else{
      this.prod_alias =null;
      
    }
  }
  updateTaxPerc(taxCatId) {
    const selTax = this.taxProdCategories.filter((taxCat) => taxCat.taxcat_id === taxCatId);
    this.taxpercentage = selTax[0].taxcat_tax_per;
    if(this.cmp_tax==2){
      this.cgstpercentage = selTax[0].cgst_tax_per;
      this.sgstpercentage = selTax[0].sgst_tax_per;
    }
    this.calcRates();

  }

  getAllAgents() {
    this.apiService.getAllAgents().subscribe((res) => {
      this.del_chellan_agents = res['data'];
    });
  }

  showList(val) {

    if (val) {
      this.listing = false; // Invoice Page
      this.terms = this.sales_settings.delivery_challan_default_terms;
      setTimeout(() => {
        this.newInvoice(this.listing);
      }, 500);

    } else {
      this.listing = true; // Listing Page
    }

  }

  updateTotal() {

  }

  searchItem() {
    setTimeout(() => {
      if(this.dflt_search_methd){
        this.selectproductId.focus();
      }else{
        this.selctbarcode.nativeElement.focus();
      }
      
    }, 500);
  }

  goBack(stepper: MatStepper) {
    stepper.previous();
  }

  goNext(stepper: MatStepper) {
    stepper.next();
  }

  newQtn(stepper: MatStepper, formdata, type) {
    // this.withoutStock =1;
    this.salesItems = [];
    this.totItemprice = 0;
    this.totItemDisc = 0;
    this.totVatAmnt = 0;
    this.totcgstAmnt = 0;
    this.totsgstAmnt = 0;
    this.totigstAmnt = 0;
    this.net_disc = 0;
    this.resetProdForm();
    formdata.reset();
    this.nextRefNo();
    this.sqdrf_id = '';
    this.sq_inv_no = '';
    this.editMessage = false;

    if (type == 'b')
      stepper.reset();

    this.saleqt_date = new Date();
    setTimeout(() => {
      this.sale_pay_type = 1;
      this.cust_type = 2;
    }, 500);

  }

  newInvoice(listing) {


    this.salesItems = [];
    this.totItemprice = 0;
    this.totItemDisc = 0;
    this.totVatAmnt = 0;
    this.totcgstAmnt = 0;
    this.totsgstAmnt = 0;
    this.totigstAmnt = 0;
    this.net_disc = 0;
    this.resetProdForm();
    this.formStep1.reset();
    this.nextRefNo();
    this.sqdrf_id = '';
    this.sq_inv_no = '';
    this.mainStepper.reset();
    this.editMessage = false;

    this.saleqt_date = new Date();
    setTimeout(() => {
      this.sale_pay_type = 1;
      this.cust_type = 2;
    }, 500);
  }



  resetProds(stepper: MatStepper) {
    this.salesItems = [];
    this.totItemprice = 0;
    this.totItemDisc = 0;
    this.totVatAmnt = 0;
    this.totcgstAmnt = 0;
    this.totsgstAmnt = 0;
    this.totigstAmnt = 0;
    this.net_disc = 0;
    stepper.previous();
  }

  proceedAddProd(stepper: MatStepper) {
    //this.validateForm();
    this.validateForm1();
  
    if(this.cmp_tax==2){
      this.iGSTchecking();
    }
    if (this.printError.length <= 0) {
      stepper.next();
    }
  }

  proceedToTerms(stepper: MatStepper) {
    if (this.sales_inv_no && !isNaN(this.sales_inv_no)){
      this.validateForm();
      if(!this.validateForm()){
        // alert("Delivery quantity greater than Balance quantity..");
        return false;
      }
    }

    if (this.salesItems.length > 0) {

      this.selectError = false;
      
      stepper.next();
    } else {
      this.selectError = true;
    }
    $('.close').trigger('click');
  }

  checkAmount() {
    
    this.inv_amnt = this.totItemprice - this.totItemDisc + this.totVatAmnt - this.net_disc;
    // this.inv_amnt = Math.round(this.inv_amnt * 1000) / 1000;
    this.recivd_amnt = this.totItemprice - this.totItemDisc + this.totVatAmnt - this.net_disc;
    // this.recivd_amnt = Math.round(this.recivd_amnt * 1000) / 1000;

    this.bal_amnt = this.inv_amnt - this.recivd_amnt;
    // this.bal_amnt = Math.round(this.bal_amnt * 1000) / 1000;
    setTimeout(() => {
      this.selctRecAmnt.nativeElement.focus();
      this.selctRecAmnt.nativeElement.select();
    }, 1000);

  }

  changeAmnt() {
    this.bal_amnt = this.recivd_amnt - this.inv_amnt;
    this.bal_amnt = Math.round(this.bal_amnt * 1000) / 1000;

  }

  searchDefaultLedger(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    if (this.sale_pay_type === 2)
      searchval.append('ledger_grp', '3');
    else if (this.sale_pay_type === 3)
      searchval.append('ledger_grp', '4');

    this.apiService.searchLedgerByAccGroup(searchval).subscribe((res) => {
      this.defLedger = res['data'];
    });
  }


  checkAddrs(val) {
    if (val) {
      this.delivry_addr = this.cust_addr;
    } else {
      this.delivry_addr = '';
    }
  }

  validateForm1() {
    this.printError = [];
    if (this.cust_type === "" || this.cust_type === undefined || this.cust_type === null) {
      this.printError.push({ 'cust_type': 'Required' });
      $('#cust_type').css("border", "1px solid red");
    }
    else {
      $('#cust_type').css("border", "1px solid #dedede");
    }

    if (this.cust_type == 2) {
      if (this.cust_id === "" || this.cust_id === undefined || this.cust_id === null) {
        this.printError.push({ 'cust_id': 'Required' });
        $('#cust_id').css("border", "1px solid red");
      }
      else {
        $('#cust_id').css("border", "1px solid #dedede");
      }
    } else {
      if (this.cust_name === "" || this.cust_name === undefined || this.cust_name === null) {
        this.printError.push({ 'cust_name': 'Required' });
        $('#cust_name').css("border", "1px solid red");
      }
      else {
        $('#cust_name').css("border", "1px solid #dedede");
      }
    }

    if (this.saleqt_date === "" || this.saleqt_date === undefined || this.saleqt_date === null) {
      this.printError.push({ 'saleqt_date': 'Required' });
      $('#saleqt_date').css("border", "1px solid red");
    }
    else {
      $('#saleqt_date').css("border", "1px solid #dedede");
    }


  }

  addChallan(stepper: MatStepper, formdata: { value: any; }) {
    formdata.value.saleqt_date = (formdata.value.saleqt_date) ? this.apiService.formatDate(formdata.value.saleqt_date) : formdata.value.saleqt_date;
    formdata.value.valid_till_date = (formdata.value.valid_till_date) ? this.apiService.formatDate(formdata.value.valid_till_date) : formdata.value.valid_till_date;
    formdata.value.sq_total = this.totItemprice - this.totItemDisc + this.totVatAmnt - this.net_disc;
    formdata.value.sq_total_item_disc = this.totItemDisc;
    formdata.value.sq_disc = this.net_disc;
    formdata.value.sq_tax = this.totVatAmnt;
    formdata.value.sq_cgst_tax = this.totcgstAmnt;
    formdata.value.sq_sgst_tax = this.totsgstAmnt;  
    formdata.value.sq_suply_state=((this.cmp_tax==2)&&(this.sply_st_id))? this.sply_st_id:this.branch_state;
    if((this.cmp_tax==2)&&(this.igst)){
      formdata.value.sq_igst_tax = this.totigstAmnt;
      formdata.value.del_chellan_is_igst = 1;
    }
    formdata.value.sale_items = this.salesItems;
    formdata.value.terms = this.terms;
    formdata.value.cnvert_ref_no = this.terms;
    formdata.value.withStock = this.sales_settings.delivery_challan_stock;
    formdata.value.sq_branch_qt_no = this.sq_branch_qt_no;
    formdata.value.qt_id = this.qt_id;
    formdata.value.gd_id = this.gd_id;
    formdata.value.del_chellan_note = this.del_chellan_note;
    formdata.value.del_chellan_tax_type = this.purchtypesel - 1;
    if(this.sales_invoice_id)
    { 
     
      formdata.value.sales_invoice_id = this.sales_invoice_id;
    }
    this.pageLoadingImg = true;
    this.apiService.addChallan(formdata.value).subscribe((res) => {
      if (res.message) {
        this.coreService.showMessage(res.message['msg']);
        this.saleData = res.message['preview'];
        // this.is_prew_description = this.saleData['del_chellan_sub'].item_descrp;

        this.pageLoadingImg = false;

        stepper.next();
        this.sqdrf_id = '';
        this.sq_inv_no = '';
        this.disable =false;

        this.getDraftList();
        this.listQtn(1);
        this.newQtn(stepper, formdata, '');
      }
    });
  }


  updateChallan(stepper: MatStepper, formdata: { value: any; }) {

    


    formdata.value.saleqt_date = (formdata.value.saleqt_date) ? this.apiService.formatDate(formdata.value.saleqt_date) : formdata.value.saleqt_date;
    formdata.value.valid_till_date = (formdata.value.valid_till_date) ? this.apiService.formatDate(formdata.value.valid_till_date) : formdata.value.valid_till_date;
    formdata.value.sq_total = this.totItemprice - this.totItemDisc + this.totVatAmnt - this.net_disc;
    formdata.value.sq_total_item_disc = this.totItemDisc;
    formdata.value.sq_disc = this.net_disc;
    formdata.value.sq_tax = this.totVatAmnt;
    formdata.value.sq_cgst_tax = this.totcgstAmnt;
    formdata.value.sq_sgst_tax = this.totsgstAmnt;  
    formdata.value.sq_suply_state=((this.cmp_tax==2)&&(this.sply_st_id))? this.sply_st_id:this.branch_state;
    if((this.cmp_tax==2)&&(this.igst)){
      formdata.value.sq_igst_tax = this.totigstAmnt;
      formdata.value.del_chellan_is_igst = 1;
    }
    formdata.value.sale_items = this.salesItems;
    formdata.value.terms = this.terms;
    formdata.value.gd_id = this.gd_id;
    formdata.value.del_chellan_tax_type = this.purchtypesel - 1;
    formdata.value.withStock = this.withStock;
    this.pageLoadingImg = true;

    this.apiService.editChallan(formdata.value).subscribe((res) => {
      if (res.message) {
        this.coreService.showMessage(res.message['msg']);
        this.saleData = res.message['preview'];
        // this.is_prew_description = this.saleData['del_chellan_sub'].filter(x => x.item_descrp);
        this.pageLoadingImg = false;

        stepper.next();
        this.sqdrf_id = '';
        this.sq_inv_no = '';

        this.getDraftList();
        this.listQtn(1);

      }
    });
  }


  getGodownList() {
    let searchval = new FormData();

    this.apiService.getGodownList(searchval).subscribe((res) => {
      this.godown_all = res['data'];

    });
  }

  nextRefNo() {
    let searchval = new FormData();

    this.apiService.getChallanNo(searchval).subscribe((res) => {
      this.dcRefNo = res['data'].del_num;
      this.qt_no = res['data'].sq_branch_qt_no;

    });
  }

  searchStkPrd(search: string) {

    let searchval = new FormData();
    searchval.append("keyword", search);
    searchval.append('stk_stat', '1');
    if(this.prod_alias){
      searchval.append('alias_search', '1');
    }
    this.apiService.getSalesStockbyItem(searchval).subscribe((res) => {
      this.stkprd_all = res['data'];

    });
  }

  searchCustomer(search: string) {
    const searchval = new FormData();
    searchval.append('cust_name', search);
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }
  searchCustomeralias(search: string){
    const searchval = new FormData();
    searchval.append('cust_name', search);
    searchval.append('with-alias','1'); 
    this.apiService.searchCustomer(searchval).subscribe((res) => {
      this.customer = res['data'];
    });

  }


  searchAgents(search: string) {
    const searchval = new FormData();
    searchval.append('ledger_name', search);
    this.apiService.searchAgents(searchval).subscribe((res) => {
      this.del_chellan_agents = res['data'];

    });

  }

  findCustomer(cust_id) {
    const searchval = new FormData();
    searchval.append('cust_code', cust_id);

    this.apiService.getCustomerByCode(searchval).subscribe((res) => {
      this.cust_id = res['data'];
      if(res['data']){
        this.noCustomer = false;
        this.selectCustomer(this.cust_id);
      }
      else {
        this.noCustomer = true;
        this.clearCustomer();
      }

    });

  }
  resetStep(step1) {
    step1.reset();
    this.nextRefNo();
    setTimeout(() => {
      this.sale_pay_type = 1;
      this.cust_type = 2;
      this.saleqt_date = new Date();

    }, 500);

  }

  selectCustomer(cust) {
    if (cust) {
      this.cust_code = cust.cust_code;
      this.cust_addr = cust.cust_home_addr;
      this.cntct_num = cust.mobile;
      this.vat_no = cust.vat_no;
      let date = new Date(this.saleqt_date);
      this.noCustomer = false;
      this.sale_agent= cust.default_sales_agent;
      
      if(this.cmp_tax==2){
        this.cust_state_id = cust.state_id;
       this.iGSTchecking();
        }

      if (cust.due_days) {
        date.setDate(date.getDate() + Number(cust.due_days));
      } else {
        date.setDate(date.getDate() + 30);
      }
      this.valid_till_date = new Date(date);
    }
  }

  iGSTchecking(){

    if(this.cmp_tax==2){
      
      // for is igst verify
      if(((this.sply_st_id!=this.branch_state)&&(this.sply_st_id))||((this.cust_state_id!=this.branch_state)&&(this.cust_state_id)&&(this.cust_id))){
        this.igst=true;
      }else{
        this.igst=false;
      }
      if((this.sply_st_id==this.branch_state)&&(this.sply_st_id)){
        this.igst=false;
        }
      }
      console.log(this.igst);
      
  }

  updateDate(cust) {
    if (cust) {

      let date = new Date(this.saleqt_date);

      if (cust.due_days) {
        date.setDate(date.getDate() + Number(cust.due_days));
      } else {
        date.setDate(date.getDate() + 30);
      }
      this.valid_till_date = new Date(date);
    }
  }


  clearCustomer() {
    this.cust_code = '';
    this.cust_addr = '';
    this.cntct_num = '';
    this.vat_no = '';
  }

  selectDiscType() {

    // if(itemDiscTyp == 1){

    // }
    this.calcRates();

  }

  searchStkPrdBarcode(search: any) {
    let searchval = new FormData();
    searchval.append("barcode", search);
    this.apiService.getStockbyBarcodeSales(searchval).subscribe((res) => {
      // this.stkprd_all = res['data'];
      if (res.data) {
        this.stkprdsel = res.data;
        this.selectedProduct(res.data.prd_id, this.gd_id, this.stkprdsel);
        this.selectQty.nativeElement.focus();
        this.selectQty.nativeElement.select();

      } else {
        this.stkprdsel = {
          prd_name: null
        };
        this.stkprdsel = "";
        this.selctedProd = {};
        this.qty = '';
        this.rate = '';
        this.unit = '';
        this.disc_amnt = 0;
        // this.prd_tax_cat_id = '';
        this.tax_amnt = '';
        this.grnd_totl = '';
        this.item_dicrptn = '';
        this.godownStocks = [];
        this.branchStocks = [];
        this.lastInvoice = [];
        this.isEdit = false;
        this.sl_no = '';
        this.base_qty = '';
        this.unit_name = '';
      }

    });
  }

  selectedProduct(prd_id, gd_id, stkprdsel) {

    this.printError = [];

    if (this.gd_id === "" || this.gd_id === undefined) {
      this.printError.push({ 'gdErr': 'Required' });
      $('#gd_id').css("border", "1px solid red");
    }
    else {
      $('#gd_id').css("border", "1px solid #dedede");
    }



    if (this.printError.length <= 0) {

      if (gd_id) {
        gd_id = gd_id;
      } else {
        gd_id = 0;
      }

      this.getGodownWiseStock(prd_id);
      this.getBranchWiseStock(prd_id);
      this.getStockDetails(prd_id, gd_id);
      if (this.cust_id) {
        this.getLastChallanToCust(prd_id, this.cust_id.cust_id);
      }
      if (this.purchtypesel == 1)
        this.prd_tax_cat_id = Number(stkprdsel.prd_tax_cat_id);

      this.calcRates();
      setTimeout(() => {
        this.selectQty.nativeElement.focus();
        this.selectQty.nativeElement.select();

      }, 1000);
    }
  }

  getGodownWiseStock(prd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    this.godownStocksTotal = 0;

    this.apiService.getGodownWiseStock(searchval).subscribe((res) => {
      this.godownStocks = res['data']['data'];
      this.godownStocksTotal = res['data']['total'];
    });

  }

  getLastChallanToCust(prd_id, cust_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('cust_id', cust_id);
    this.apiService.getLastChallanToCust(searchval).subscribe((res) => {
      this.lastInvoice = res['data'];
      this.lastCust = 0;

      if (this.lastInvoice[0])
        this.lastCust = this.lastInvoice[0]['del_chellan_sub_rate'];
      else
        this.lastCust = 0;

    });

  }

  getBranchWiseStock(prd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    this.branchStocksTotal = 0;

    this.apiService.getBranchWiseStock(searchval).subscribe((res) => {
      this.branchStocks = res['data']['data'];
      this.branchStocksTotal = res['data']['total'];

    });

  }

  getStockDetails(prd_id, gd_id) {
    const searchval = new FormData();
    searchval.append('prd_id', prd_id);
    searchval.append('gd_id', gd_id);
    this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
      this.selctedProd = res['data'];
      this.disable = true;
      this.unit = (this.stkprdsel.produnit_unit_id ? this.stkprdsel.produnit_unit_id : this.selctedProd.prd_base_unit_id);
     
      if (this.selctedProd.prd_default_unit_id) {
        
          this.unit = this.selctedProd.prd_default_unit_id;
       
      }
     
     
      this.barcode = this.selctedProd.prd_barcode;
      this.prd_location = this.selctedProd.prd_location;
      this.available_stock= this.selctedProd.stock_qty;
      this.unit_available_stock= this.selctedProd.stock_qty;
      this.bs_prate = this.selctedProd.bs_prate;

      this.selectedUnit(this.unit);
      this.calcRates();
      if (this.purchtypesel == 1)
        this.updateTaxPerc(this.selctedProd.prd_tax_cat_id);


    });

  }

  resetProdForm() {
    this.barcode = '';
    this.stkprdsel = "";
    this.selctedProd = {};
    this.qty = '';
    this.rate = '';
    this.unit = '';
    this.disc_amnt = 0;
    // this.prd_tax_cat_id = '';
    this.tax_amnt = '';
    this.grnd_totl = '';
    this.item_dicrptn = '';
    this.godownStocks = [];
    this.godownStocksTotal = 0;
    this.branchStocks = [];
    this.branchStocksTotal = 0;
    this.lastInvoice = [];
    this.lastCust = 0;
    this.isEdit = false;
    this.sl_no = '';
    this.base_qty = '';
    this.unit_name = '';
    this.excl_vat = 1;
    this.selectproductId.focus();

  }

  selectedUnit(unit) {
    const prdUnts = this.selctedProd.prod_units.filter((ProdUnits) => ProdUnits.produnit_unit_id === unit);
    this.rate = (prdUnts[0].sur_unit_rate ? prdUnts[0].sur_unit_rate : prdUnts[0].unit_base_qty * this.selctedProd.base_unit_rate);
    this.base_qty = prdUnts[0].unit_base_qty;
    this.unit_name = prdUnts[0].unit_display;

    this.unit_available_stock = this.available_stock / this.base_qty;
    this.unit_bs_prate = this.bs_prate * this.base_qty;
    this.calcRates();
  }

  calcRates() {

    this.qty = this.qty ? this.qty : 1;
    this.rate = this.rate ? this.rate : 0;
    this.itemDisc = this.disc_amnt ? this.disc_amnt : 0;

    this.purchAmnt = this.qty * this.rate;


    if (this.item_disc_type == 1)
      this.itemDisc = this.itemDisc;
    else
      this.itemDisc = (this.purchAmnt * this.itemDisc) / 100;

    if (this.excl_vat == 1) {
      this.tax_amnt = ((this.purchAmnt - (this.itemDisc)) * this.taxpercentage) / 100;
      this.taxprice = ((this.rate - this.itemDisc) * this.taxpercentage) / 100;
      this.itemRate = this.rate;
    } else {
      this.tax_amnt = (this.purchAmnt - (this.itemDisc)) - (((this.purchAmnt - (this.itemDisc)) * 100) / (this.taxpercentage + 100));
      this.taxprice = (this.rate - this.itemDisc) - (((this.rate - this.itemDisc) * 100) / (this.taxpercentage + 100));
      this.itemRate = (((this.rate - this.itemDisc) * 100) / (this.taxpercentage + 100));
    }
    if (this.excl_vat == 1)
      this.itemTotal = this.purchAmnt + this.tax_amnt;
    else
      this.itemTotal = this.purchAmnt;

    this.grnd_totl = this.itemTotal - this.itemDisc;
    this.grnd_totl = this.itemTotal - this.itemDisc;

  }




  addSaleItem(selected: { value: any; }, close) {


    this.printError = [];
    if (this.gd_id === "" || this.gd_id === undefined) {
      this.printError.push({ 'gdErr': 'Required' });
      $('#gd_id').css("border", "1px solid red");
    }
    else {
      $('#gd_id').css("border", "1px solid #dedede");
    }

    if (this.stkprdsel == "" || this.stkprdsel == undefined) {
      this.printError.push({ 'prdErr': 'Required' });
      $('#selprod').css("border", "1px solid red");
    }
    else {
      $('#selprod').css("border", "1px solid #dedede");
    }

    if (this.qty == "" || this.qty <= 0) {
      this.printError.push({ 'qtyErr': 'Required' });
      $('#qty').css("border", "1px solid red");
    }
    else {
      $('#qty').css("border", "1px solid #dedede");
    }


    if (this.printError.length <= 0) {

      this.resDup = this.coreService.checkSaleItem(this.salesItems, Number(selected.value.stkprdsel.prd_id), Number(selected.value.rate), selected.value.unit, selected.value.item_dicrptn);
      var existingArray = this.coreService.findItemSales(this.salesItems, Number(selected.value.stkprdsel.prd_id), Number(selected.value.rate), selected.value.unit, selected.value.item_dicrptn);
      if (this.resDup && existingArray.length > 0) {

        var existQty = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).del_chellan_sub_qty;
        // var existPrc = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).del_chellan_sub_rate;
        this.taxvaltotqty = ((Number(existQty) + Number(selected.value.qty * this.base_qty)) * selected.value.tax_amnt / this.base_qty);
        this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).del_chellan_sub_qty = Number(existQty) + Number(selected.value.qty);
        // this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).del_chellan_sub_rate = Number(existPrc) + Number(selected.value.rate);
        this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).del_chellan_sub_tax = this.taxvaltotqty;
        // this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty + this.base_qty;


      } else {
        if (this.gd_id == undefined)
          this.gd_id = 0;
        this.salesItems.push({
          "sl_no": (this.salesItems.length) + 1, 'del_chellan_sub_prd_id': selected.value.stkprdsel.prd_id, 'prd_name': selected.value.stkprdsel.prd_name,'prd_alias': selected.value.stkprdsel.prd_alias,'prd_code': selected.value.stkprdsel.prd_code, 'ean': selected.value.stkprdsel.prd_ean, 'del_chellan_sub_stock_id': selected.value.stkprdsel.bs_stock_id, 'del_chellan_sub_qty': selected.value.qty,
          'del_chellan_sub_rate': this.itemRate, 'del_chellan_sub_amnt': this.itemRate * selected.value.qty, 'del_chellan_sub_tax_amnt': selected.value.tax_amnt, 'del_chellan_sub_tax': selected.value.tax_amnt / selected.value.qty, 'del_chellan_sub_tax_per': this.taxpercentage, 'del_chellan_sub_price': this.purchAmnt,
          'purchpricesingle': this.itemRate / this.base_qty, 'del_chellan_sub_gd_id': this.gd_id, 'taxvalperqty': selected.value.tax_amnt / this.base_qty,
          'base_qty': this.base_qty, 'prd_unit_name': this.unit_name, 'del_chellan_sub_unit_id': selected.value.unit, 'prd_barcode': selected.value.stkprdsel.prd_barcode, 'prd_tax_cat_id': selected.value.prd_tax_cat_id
          , 'item_desc': this.item_dicrptn, 'item_disc': this.itemDisc, 'prd_type': selected.value.stkprdsel.prd_type, 'vat_type': selected.value.excl_vat, 'item_disc_type': selected.value.item_disc_type,'del_chellan_sub_cgst_tax_per': this.cgstpercentage,'del_chellan_sub_sgst_tax_per': this.sgstpercentage,
        });

      }

      this.is_description = this.salesItems.filter(x => x.item_desc !== '');

      this.sumSaleTotal();
      this.resetProdForm();
      this.changeAmnt();
      selected = null;
      if (close == 'close')
        $('.close').trigger("click");

    }

  }


  editSaleItem(selected: { value: any; }, close) {


    this.printError = [];
    if (this.gd_id === "" || this.gd_id === undefined) {
      this.printError.push({ 'gdErr': 'Required' });
      $('#gd_id').css("border", "1px solid red");
    }
    else {
      $('#gd_id').css("border", "1px solid #dedede");
    }

    if (this.stkprdsel == "" || this.stkprdsel == undefined) {
      this.printError.push({ 'prdErr': 'Required' });
      $('#selprod').css("border", "1px solid red");
    }
    else {
      $('#selprod').css("border", "1px solid #dedede");
    }

    if (this.qty == "" || this.qty <= 0) {
      this.printError.push({ 'qtyErr': 'Required' });
      $('#qty').css("border", "1px solid red");
    }
    else {
      $('#qty').css("border", "1px solid #dedede");
    }

    if (this.printError.length <= 0) {
      this.selectedUnit(this.unit);

      // this.resDup = this.coreService.checkSaleItem(this.salesItems, selected.value.stkprdsel.prd_id, selected.value.rate, selected.value.unit, selected.value.item_dicrptn);
      // var existingArray = this.coreService.findItemSales(this.salesItems, selected.value.stkprdsel.prd_id, selected.value.rate, selected.value.unit, selected.value.item_dicrptn);
      // if (this.resDup && existingArray.length > 0) {

      //   var existQty = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty;
      //   // var existPrc = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).del_chellan_sub_rate;
      //   this.taxvaltotqty = ((Number(existQty) + Number(selected.value.qty * this.base_qty)) * selected.value.tax_amnt / this.base_qty);
      //   this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).del_chellan_sub_qty = Number(existQty) + Number(selected.value.qty);
      //   // this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).del_chellan_sub_rate = Number(existPrc) + Number(selected.value.rate);
      //   this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).del_chellan_sub_tax = this.taxvaltotqty;
      //   // this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty = this.salesItems.find(x => x.sl_no === existingArray[0].sl_no).base_qty + this.base_qty;


      // } else {
      if (this.gd_id == undefined)
        this.gd_id = 0;
      // this.salesItems.push({
      //   "sl_no": (this.salesItems.length) + 1, 'del_chellan_sub_prd_id': selected.value.stkprdsel.prd_id, 'prd_name': selected.value.stkprdsel.prd_name, 'ean': selected.value.stkprdsel.prd_ean, 'del_chellan_sub_stock_id': selected.value.stkprdsel.bs_stock_id, 'del_chellan_sub_qty': selected.value.qty,
      //   'del_chellan_sub_rate': this.itemRate, 'del_chellan_sub_amnt': this.itemRate * selected.value.qty, 'del_chellan_sub_tax_amnt': selected.value.tax_amnt, 'del_chellan_sub_tax': selected.value.tax_amnt / selected.value.qty, 'del_chellan_sub_tax_per': this.taxpercentage, 'del_chellan_sub_price': this.purchAmnt,
      //   'purchpricesingle': this.itemRate / this.base_qty, 'del_chellan_sub_gd_id': this.gd_id, 'taxvalperqty': selected.value.tax_amnt / this.base_qty,
      //   'base_qty': this.base_qty, 'prd_unit_name': this.unit_name, 'del_chellan_sub_unit_id': selected.value.unit, 'prd_barcode': selected.value.stkprdsel.prd_barcode, 'prd_tax_cat_id': selected.value.prd_tax_cat_id
      //   , 'item_desc': this.item_dicrptn, 'item_disc': this.itemDisc, 'prd_type': selected.value.stkprdsel.prd_type, 'vat_type': selected.value.excl_vat
      // });

      this.salesItems.find(x => x.sl_no === selected.value.sl_no).del_chellan_sub_prd_id = selected.value.stkprdsel.prd_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_name = selected.value.stkprdsel.prd_name;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).ean = selected.value.stkprdsel.prd_ean;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).del_chellan_sub_stock_id = selected.value.stkprdsel.bs_stock_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).del_chellan_sub_qty = selected.value.qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).del_chellan_sub_rate = this.itemRate;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).del_chellan_sub_amnt = this.itemRate * selected.value.qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).del_chellan_sub_tax_amnt = selected.value.tax_amnt;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).del_chellan_sub_tax = selected.value.tax_amnt / selected.value.qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).del_chellan_sub_tax_per = this.taxpercentage;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).del_chellan_sub_price = this.purchAmnt;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).purchpricesingle = this.itemRate / this.base_qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).del_chellan_sub_gd_id = this.gd_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).taxvalperqty = selected.value.tax_amnt / this.base_qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).base_qty = this.base_qty;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_unit_name = this.unit_name;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).del_chellan_sub_unit_id = selected.value.unit;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_barcode = selected.value.stkprdsel.prd_barcode;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_tax_cat_id = selected.value.prd_tax_cat_id;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).item_desc = this.item_dicrptn;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).item_disc = this.itemDisc;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).prd_type = selected.value.stkprdsel.prd_type;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).vat_type = selected.value.excl_vat;
      this.salesItems.find(x => x.sl_no === selected.value.sl_no).item_disc_type = selected.value.item_disc_type;

      // }

      this.is_description = this.salesItems.filter(x => x.item_desc !== '');

      this.sumSaleTotal();
      this.resetProdForm();
      this.changeAmnt();

      if (close == 'close')
        $('.close').trigger("click");

    }

  }


  // delete product from table
  deleteProd(data) {
    var remopstk = confirm("Removing Selected Product from the List ?");
    if (remopstk) {
      var type = data.sl_no,
        i;
      for (i = this.salesItems.length - 1; i >= 0; --i) {
        if (this.salesItems[i].sl_no == type) {
          this.salesItems.splice(i, 1);
        }
      }


      this.sumSaleTotal();
      this.changeAmnt();

    }

  }

  // Edit product from List
  editProd(data) {
     
    
    if (data) {

      const searchval = new FormData();
      searchval.append('prd_id', data.del_chellan_sub_prd_id);
      searchval.append('gd_id', data.del_chellan_sub_gd_id);
      this.apiService.getsaleProdDetails(searchval).subscribe((res) => {
        this.selctedProd = res['data'];
        
       
        if (this.purchtypesel == 1)
          this.updateTaxPerc(this.selctedProd.prd_tax_cat_id);
      });

      this.getGodownWiseStock(data.del_chellan_sub_prd_id);
      this.getBranchWiseStock(data.del_chellan_sub_prd_id);
      if (this.cust_id) {
        this.getLastChallanToCust(data.del_chellan_sub_prd_id, this.cust_id.cust_id);
      }
      this.apiService.getEditChallanItem(data).subscribe((res: any) => {
        this.sl_no = data.sl_no;
        this.stkprdsel = res.data.product;
        this.qty = data.del_chellan_sub_qty;
        this.unit = data.del_chellan_sub_unit_id;
        this.excl_vat = data.vat_type;
        if (this.excl_vat == 2) {
          this.rate = data.del_chellan_sub_rate + data.del_chellan_sub_tax;
        } else {
          this.rate = data.del_chellan_sub_rate;
        }
        if (data.item_disc_type == 2)
          this.disc_amnt = (data.item_disc / (data.del_chellan_sub_qty * data.del_chellan_sub_rate)) * 100;
        else
          this.disc_amnt = data.item_disc;

        this.prd_tax_cat_id = Number(data.prd_tax_cat_id);
        this.item_dicrptn = data.item_desc;
        this.gd_id = data.del_chellan_sub_gd_id;
        this.calcRates();
        $('#searchItem').trigger('click');

      });

      this.isEdit = true;

    }
  }

  sumSaleTotal() {

    this.totItemprice = 0;
    this.totItemDisc = 0;
    this.totVatAmnt = 0;
    this.totcgstAmnt = 0;
    this.totsgstAmnt = 0;
    this.totigstAmnt = 0;
console.log(this.salesItems);

    for (var i = 0; i < this.salesItems.length; i++) {

      this.totItemprice = Number(this.totItemprice) + (Number(this.salesItems[i]['del_chellan_sub_qty']) * Number(this.salesItems[i]['del_chellan_sub_rate']));
      this.totItemDisc = Number(this.totItemDisc) + Number(this.salesItems[i]['item_disc']);
      this.totVatAmnt = Number(this.totVatAmnt) + Number(this.salesItems[i]['del_chellan_sub_tax_amnt']);

      if(this.cmp_tax==2){
        this.totcgstAmnt = Number(this.totcgstAmnt) +  Number(((this.salesItems[i]['del_chellan_sub_amnt']-this.salesItems[i]['item_disc'])/100)*this.salesItems[i]['del_chellan_sub_cgst_tax_per']);
        this.totsgstAmnt = Number(this.totsgstAmnt) +  Number(((this.salesItems[i]['del_chellan_sub_amnt']-this.salesItems[i]['item_disc'])/100)*this.salesItems[i]['del_chellan_sub_sgst_tax_per']);
      }
      
    }

     // for IGST Case
     if((this.cmp_tax==2)){
      // this.totsgstAmnt=0;
      // this.totcgstAmnt=0;
      this.totigstAmnt=this.totVatAmnt;

    }
  }

  getTemplete() {

    this.apiService.getSettings().subscribe((res) => {

      this.savedTemplate = {
        title_text: res.data.prnt_title_text,
        color: '#000',
        email_color: '#000',
        font_size: '12',
        orientation: "P",
        paper: 'A4-P',
        m_left: '',
        m_top: '',
        m_right: '',
        m_bottom: '',
        logo: res.data.prnt_header_logo,
        header_type: res.data.prnt_header_type,
        header_img: [],
        header_all_page: false,
        header_due: false,
        sl_no: false,
        item_desc: false,
        tax_detail: false,
        tax_type: 0,
        name_type: 'N',
        footer_text: res.data.prnt_display_footer_text,
        header_height: res.data.prnt_nt_header_height,
        footer_height: res.data.prnt_nt_footer_height,
        page_no_display: res.data.prnt_nt_display_page_no,
        left_h: res.data.prnt_nt_left_space,
        botom_h: res.data.prnt_nt_bottom_space,
        page_format: res.data.prnt_nt_page_no_disply_frmt
      }
      if (res.data.prnt_header_logo) {
        this.logoImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_logo_img_path;
      } else {
        this.logoImgUrl = '';
      }
      this.footImgUrl = (res.data.prnt_footer_img_path) ? this.apiService.PHP_API_SERVER + '/' + res.data.prnt_footer_img_path : '';
      if (res.data.prnt_header_type == 2) {
        this.tempImgUrl = this.apiService.PHP_API_SERVER + '/' + res.data.prnt_header_img_path;
      } else {
        this.tempImgUrl = '';
      }

    });
  }


  selectTaxType(ptypeid) {

    // purchase_types = [
    //   { id: 1, name: 'Standard Rated Domestic' },
    //   { id: 2, name: 'Zero Rated Domestic' },
    //   { id: 3, name: 'Exempt Purchase' }
    //
    // ];


    if (ptypeid === 3 || ptypeid === 2) {
      // to set tax category with 0% tax percentage
      this.taxListCategories = this.taxProdCategories.filter((taxCat) => Number(taxCat.taxcat_tax_per) == 0);

      if (this.taxListCategories.length > 0) {
        this.prd_tax_cat_id = this.purch_type_taxcat_id = this.taxListCategories[0].taxcat_id;
        this.taxpercentage = this.taxListCategories[0].taxcat_tax_per
      }
      this.zeroType = true;
    } else {
      this.zeroType = false;

    }
  }

  draftQuotation(formdata: { value: any; }) {
    formdata.value.saleqt_date = (formdata.value.saleqt_date) ? this.apiService.formatDate(formdata.value.saleqt_date) : formdata.value.saleqt_date;
    formdata.value.valid_till_date = (formdata.value.valid_till_date) ? this.apiService.formatDate(formdata.value.valid_till_date) : formdata.value.valid_till_date;
    formdata.value.sq_total = this.totItemprice - this.totItemDisc + this.totVatAmnt - this.net_disc;
    formdata.value.sq_total_item_disc = this.totItemDisc;
    formdata.value.sq_disc = this.net_disc;
    formdata.value.sq_tax = this.totVatAmnt;
    formdata.value.sale_items = this.salesItems;
    // formdata.value.terms = this.terms;
    formdata.value.del_chellan_note = this.del_chellan_note;

    this.draftLoader = true;

    this.apiService.draftSalesQuotn(formdata.value).subscribe((res) => {
      if (res.message) {
        this.coreService.showMessage(res.message);
        this.draftLoader = false;
        this.getDraftList();


      }
    });
  }

  getDraftList() {

    this.apiService.getSalesDraftList('').subscribe((res) => {
      this.draftList = res.data;

    });
  }

  removeSaleFromDraft(draft_id) {

    if (confirm("Are You Sure You Want To Remove This Quotation?")) {
      let searchval = new FormData();
      searchval.append("sqdrf_id", draft_id);
      this.apiService.removeSalesDraft(searchval).subscribe((res) => {
        this.coreService.showMessage("Removed Successfully");
        this.getDraftList();

      });
    }
  }


  


  getDraftDet(draft_id) {
    let searchval = new FormData();
    searchval.append("sqdrf_id", draft_id);
    this.imporDrafttLoader = true;

    this.apiService.getSalesDraft(searchval).subscribe((res: any) => {

      this.salesItems = res.data.del_chellan_drft_sub;
      if (res.data.sqdrf_valid_till) {
        this.valid_till_date = new Date(res.data.sqdrf_valid_till);
      } else {
        this.valid_till_date = '';
      }
      this.saleqt_date = new Date(res.data.sqdrf_date);
      this.cust_type = res.data.sqdrf_cust_type;
      this.cust_code = res.data.sqdrf_cust_code;
      this.cust_name = res.data.sqdrf_cust_name;
      this.cust_id = res.data.customer;
      this.cust_addr = res.data.sqdrf_cust_address;
      this.cntct_num = res.data.sqdrf_cust_phone;
      this.vat_no = res.data.sqdrf_cust_tin;
      this.net_disc = res.data.sqdrf_disc;
      this.sqdrf_id = res.data.sqdrf_id;
      this.del_chellan_note = res.data.sqdrf_notes;


      // this.sale_agent = res.data.sqdrf_del_chellan_agent;
      this.sumSaleTotal();
      this.changeAmnt();

      this.imporDrafttLoader = false;

      $('.close').trigger("click");



    });
  }
  searchInp(keyword) {
    let searchval = new FormData();
    if(keyword)
    searchval.append("keyword", keyword);
    this.apiService.getDeliveryChallanList(searchval, 1).subscribe((res) => {
      this.list_sales = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  listQtn(pageNo = 1) {
    let searchval = new FormData();
    // if(keyword)
    // searchval.append("keyword", keyword);
    this.apiService.getDeliveryChallanList(searchval, pageNo).subscribe((res) => {
      this.list_sales = res.data.data;
      this.curpage = res.data['current_page'];
      this.lastpage = res.data['last_page'];
      this.from = res.data['from'];
      this.pgEnd(this.curpage, this.lastpage);
      this.pgStart(this.curpage);

    });
  }

  pgEnd(curr, end) {
    if (curr == end)
      this.pgend = true;
    else
      this.pgend = false;

  }

  pgStart(curr) {
    if (curr == 1)
      this.pgstart = true;
    else
      this.pgstart = false;
  }

  importSales(data) {

    // if (data.del_chellan_due_sub.length <= 0) {

      let searchval = new FormData();
      searchval.append("del_chellan_inv_no", data.del_chellan_inv_no);
      this.importLoader = true;
      this.apiService.getDelChallanDet(searchval).subscribe((res: any) => {

       
        this.sales_invoice_id = "";
        this.dcRefNo = res.data.del_chellan_inv_no;
        this.qt_no = res.data.del_chellan_branch_inv;
        this.sq_inv_no = res.data.del_chellan_inv_no;
        this.purchtypesel = res.data.del_chellan_tax_type + 1;
        this.salesItems = res.data.delivery_challan_sub;
        this.del_chellan_po_no = res.data.del_chellan_po_no;
        this.del_chellan_with_stock =res.data.del_chellan_with_stock;
        this.gd_id = res.data.godown_id;
        if (res.data.del_chellan_due_date) {
          this.valid_till_date = new Date(res.data.del_chellan_due_date);
        } else {
          this.valid_till_date = '';
        }
        this.saleqt_date = new Date(res.data.del_chellan_date);

        if (res.data.del_chellan_cust_type == 0) {
          this.cust_type = 1;
        } else if (res.data.del_chellan_cust_type == 1) {
          this.cust_type = 2;
        }

        if (res.data.del_chellan_pay_type == 0) {
          this.sale_pay_type = 2;
        } else if (res.data.del_chellan_pay_type == 1) {
          this.sale_pay_type = 1;
        } else if (res.data.del_chellan_pay_type == 2) {
          this.sale_pay_type = 3;
        }

        if (res.data.del_chellan_acc_ledger_id > 0) {
          this.defLedger = res.data.acc_ledger;
          this.sale_acc_ledger_id = res.data.del_chellan_acc_ledger_id;
        }

        this.cust_code = (res.data.customer ? res.data.customer.cust_code : '');
        this.cust_name = res.data.del_chellan_cust_name;
        this.cust_id = res.data.customer;
        this.cust_addr = res.data.del_chellan_cust_address;
        this.cntct_num = res.data.del_chellan_cust_ph;
        this.vat_no = res.data.del_chellan_cust_tin;
        this.net_disc = res.data.del_chellan_discount;
        this.del_chellan_note = res.data.del_chellan_notes;
        this.terms = res.data.del_chellan_terms;
        this.withStock = res.data.del_chellan_with_stock;
        this.sale_agent = res.data.del_chellan_agent_ledger_id;
        this.cust_state_id=this.cust_id.state_id;
        this.sply_st_id = res.data.del_chellan_spply_place;
        this.iGSTchecking();
        this.sumSaleTotal();
        this.changeAmnt();

        this.importLoader = false;

        this.listing = false;
        this.is_description = this.salesItems.filter(x => x.item_desc !== '');

        this.editMessage = true;

      });
    // }
  }

  previewDelChallan(id) {
    let searchval = new FormData();
    searchval.append("del_chellan_inv_no", id);
    this.previewLoader = true;
    this.apiService.getDelChallanPreview(searchval).subscribe((res: any) => {
      if (res.data) {
        
        console.log("sfasafdsa");

        this.saleQtnData = res.data['preview'];
        console.log(this.saleQtnData.delivery_challan_sub);
        // this.is_prew_description = ;
        this.previewLoader = false;

      }
    });
  }


  getLogDet(id) {
    let searchval = new FormData();
    searchval.append("del_chellan_inv_no", id);
    this.editLogpreviewLoader = true;
    this.saleEditLog = [];

    this.apiService.getChallanLogPreview(searchval).subscribe((res: any) => {
      if (res.data) {

        this.saleEditLog = res.data;

      }
      this.editLogpreviewLoader = false;

    });
  }

  openModal(content: any, size) {

    this.modalRef = this.modalService.open(content, { size: size, backdrop: 'static' });
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });

  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  validateAndSubmit(){
    this.isSubmitInProg = true;
    let callFunction = 'createCustOrSup';
    if(this.userType =='ADMIN'){
      this.customer_dt.usr_type = 1;
    }else{
      this.customer_dt.usr_type = 0;
    }

    this.apiService[callFunction](this.customer_dt).subscribe((res) => {
      // console.log(this.staff);
      this.isSubmitInProg = false;
      if (res.error != null) {
        this.resultobj = res.error;
      } else {
        this.resultobj = {};
        this.modalRef.close();
        this.clearForm();
      }
    });
  }
  clearForm() {
    this.customer_dt = {
      id: '',
      ledger_id: '',
      name: '',
      alias: '',
      code: '',
      email : '',
      mobile: '',
      vat_no: '',
      op_bal: 0,
      brch_id:0,
      usr_type:0,
      is_supplier : false,
      op_bal_type: true,
      add: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      del: {
        addr: '',
        zip: '',
        city: '',
        country: '',
        state: '',
        state_code: '',
        fax: ''
      },
      due_day: '',
      van_line: 0,
      price_group: 1,
      note: ''
    };
  }

  previewSales(s_inv_no) {
    let searchval = new FormData();
    searchval.append("sales_inv_no", s_inv_no);
    this.previewLoader1 = true;
    this.apiService.getSalesDet(searchval).subscribe((res: any) => {
      if (res.data) {

        this.purchtypesel = res.data.sales_tax_type + 1;
        this.salesItems = [];

        res.data.sales_sub.forEach((element, index) => {

          let tmp= <any>{};
          tmp.del_chellan_sub_prd_id = element.salesub_prd_id;
          tmp.prd_name = element.prd_name;
          tmp.ean = element.ean;
          tmp.del_chellan_sub_stock_id = element.salesub_stock_id;
          tmp.del_chellan_sub_qty = element.salesub_qty;
          tmp.del_chellan_sub_rate = element.salesub_rate;
          tmp.del_chellan_sub_amnt = element.salesub_rate * element.salesub_qty;
          tmp.del_chellan_sub_tax_amnt = element.salesub_tax_amnt;
          tmp.del_chellan_sub_tax = element.salesub_tax;
          tmp.del_chellan_sub_tax_per = element.salesub_tax_per;
          tmp.del_chellan_sub_price = element.salesub_price;
          tmp.purchpricesingle = element.salesub_rate / element.base_qty;
          tmp.del_chellan_sub_gd_id = element.salesub_gd_id;
          tmp.taxvalperqty = element.salesub_tax / element.base_qty;
          tmp.base_qty = element.base_qty;
          tmp.prd_unit_name = element.prd_unit_name;
          tmp.del_chellan_sub_unit_id = element.salesub_unit_id;
          tmp.prd_barcode = element.prd_barcode;
          tmp.prd_tax_cat_id = element.prd_tax_cat_id;
          tmp.item_desc = element.item_desc;
          tmp.item_disc = element.item_disc;
          tmp.prd_type = element.prd_type;
          tmp.vat_type = element.vat_type;
          tmp.item_disc_type = element.item_disc_type;
          this.salesItems.push(tmp);
        });

        if (res.data.sales_due_date) {
          this.valid_till_date = new Date(res.data.sales_due_date);
        } else {
          this.valid_till_date = '';
        }
        this.saleqt_date = new Date(res.data.sales_due_date);

        if (res.data.sales_cust_type == 0) {
          this.cust_type = 1;
        } else if (res.data.sales_cust_type == 1) {
          this.cust_type = 2;
        }

        if (res.data.sales_pay_type == 0) {
          this.sale_pay_type = 2;
        } else if (res.data.sales_pay_type == 1) {
          this.sale_pay_type = 1;
        } else if (res.data.sales_pay_type == 2) {
          this.sale_pay_type = 3;
        }

        if (res.data.sales_acc_ledger_id > 0) {
          this.defLedger = res.data.acc_ledger;
          this.sale_acc_ledger_id = res.data.sales_acc_ledger_id;
        }

        this.cust_code = (res.data.customer ? res.data.customer.cust_code : '');
        this.cust_name = res.data.sales_cust_name;
        this.cust_id = res.data.customer;
        this.cust_addr = res.data.sales_cust_address;
        this.cntct_num = res.data.sales_cust_ph;
        this.vat_no = res.data.sales_cust_tin;
        this.net_disc = res.data.sales_discount;
        this.del_chellan_note = res.data.sales_notes;
        // this.sale_agent = res.data.sqdrf_sales_agent;
        this.sumSaleTotal();
        this.changeAmnt();

        this.importLoader = false;

        this.listing = false;
        this.is_description = this.salesItems.filter(x => x.item_desc !== '');
        this.withoutStock =1;



      }
      this.previewLoader1 = false;
    });
  }
  
  removeDeliveryChallan(id) {

    if (confirm("Do you wish to void this entry?")) {
      let searchval = new FormData();

      searchval.append("del_chellan_inv_no", id);
      this.apiService.voidChallan(searchval).subscribe((res) => {
        if (res.message)
          this.coreService.showMessage('Voided Successfully');
          // this.listing = true;
          this.listQtn(1);

      });

    }

  }

  getSalesSetting() {

    this.apiService.getSalesSetting().subscribe((res) => {
      if (res['data']) {
        this.sales_settings = res['data'];
        this.show_prd_code=this.sales_settings.show_prd_code
        this.dflt_search_methd=this.sales_settings.dflt_search_methd;
        this.terms = this.sales_settings.delivery_challan_default_terms;
        // this.qtn_print_style = this.sales_settings.qtn_print_style;
      } else { 
        this.show_prd_code=0;
        // this.sales_print_name=1;
        // this.qtn_print_style=1;
        this.terms = '';
      }
    });

  }
  @HostListener('document:keydown.enter', ['$event.target'])
  handleEnterKey(target: HTMLElement) {
    // Check if the target element is not an input or textarea
    if (target.tagName !== 'INPUT' && target.tagName !== 'TEXTAREA') {
      // this.nextButton.nativeElement.click()
      if(this.matindex==1){
        this.secndnextButton.nativeElement.click()
      }else if(this.matindex !=2 && this.matindex !=3){
        this.firstnextButton.nativeElement.click()
      }
    }

  }

  onStepSelected(event: any) {
    if (event.selectedIndex == event.selectedIndex && event.previouslySelectedIndex != event.selectedIndex) {

      this.matindex=event.selectedIndex;
    //  console.log(`Selected step:` ,event.selectedIndex);
    //  console.log('hehehe',event.previouslySelectedIndex);
    //  console.log('sui',this.matindex);
      
    }
  }

  validateForm() {
    this.valErrors = {};

    let retVal = true;
    this.salesItems.forEach((element, i) => {
      if (element.delivery_qty == '' || element.delivery_qty == null) {
        this.valErrors['delivery_qty' + i] = this.translate.instant('Common.Delivery_quantity_empty');
       // this.errorMessage ="The delivery quantity Empty..";
        retVal = false;
      }
     // console.log(element.balance_qty +'--'+element.delivery_qty);


      // if ((element.delivery_qty == 0) || (element.delivery_qty > element.balance_qty)) {
      //   this.valErrors['delivery_qty' + i] = { msg: 'Required' }
      //   retVal = false;
      // }

      if ((element.delivery_qty > element.balance_qty)) {
        this.valErrors['delivery_qty' + i] =  this.translate.instant('Common.The_delivery_quantity_is_greater_than_the_balance_quantity');
       // this.errorMessage1 ="The delivery quantity is greater than the balance quantity..";
        retVal = false;
      }
    });
   
   // console.log( retVal);
    return retVal;
   
  }

  selectTaxType1(i) {

    //console.log(i);
    let balanceQT = this.salesItems[i].balance_qty;
    if (this.sales_inv_no && !isNaN(this.sales_inv_no)){ 
      this.salesItems[i].balance_qty=( balanceQT - this.salesItems[i].delivery_qty);
    } 
  }
  langChange(){
    this.translate.get(['Common.The_delivery_quantity_is_greater_than_the_balance_quantity', 'Common.Delivery_quantity_empty', 'Common.standard_rated_domestic', 'Common.zero_rated_domestic', 'Common.exempt_sales','Common.registerd','Sales.excl_vat','Sales.incl_vat','Table.rate','Production.percentage']).subscribe((res: string) => {    
      
      this.purchase_types = [
        { id: 1, name: res['Common.standard_rated_domestic']},
        { id: 2, name: res['Common.zero_rated_domestic'] },
        { id: 3, name: res['Common.exempt_sales'] }
      ];

      this.cust_types = [
        { id: 2, name: res['Common.registerd'] }

      ];
      this.vat_types = [
        { id: 1, name: res['Sales.excl_vat'] },
        { id: 2, name: res['Sales.incl_vat'] }
      ];
    
      this.item_disc_types = [
        { id: 1, name: res['Table.rate'] },
        { id: 2, name: res['Production.percentage'] }
      ];
    });

    
  }

  getAllState(){
    let searchval = new FormData();
    this.apiService.getAllState(searchval).subscribe((res) => {
      this.states = res.data;
      
    });
  }

 
  
}
